import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_switch = _resolveComponent("a-switch")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_switch, _mergeProps({
      checked: _ctx.checked,
      "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event))
    }, _ctx.bindProp, {
      disabled: _ctx.switchOpts.disabled,
      onChange: _ctx.onToggle,
      onClick: _ctx.onClick
    }), null, 16, ["checked", "disabled", "onChange", "onClick"])
  ]))
}