
import { defineComponent, PropType, reactive } from 'vue';
import { SelectOptions } from '@/types/form.config';
import { SelectItem } from '@/types/base.config';

export default defineComponent({
  name: 'CheckboxWidget',
  props: {
    opts: {
      type: Object as PropType<SelectOptions>,
      default: () => ({})
    },
    modelValue: [Array, String]
  },
  emits: ['update:modelValue', 'itemChange'],
  setup(props, { emit }) {
    const options = reactive(props.opts.options as SelectItem[]);

    const onChange = (data: Array<string | number>) => {
      const retVal = data.map(x => ',' + x).reduce((p, n) => p + n).substring(1);
      emit('update:modelValue', retVal);
      emit('itemChange', props.opts.field);
    };

    const initList = () => {
      if (props.modelValue) {
        return (props.modelValue as string).split(',');
      }
      return [];
    };

    const checkList = initList();
    return { options, checkList, onChange };
  }
});
