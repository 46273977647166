import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import util from '@/utils';

import UserRetrieve from '@/components/retrieve/UserRetrieve.vue';

createApp(App).use(Antd).use(store).use(router)
  .use(util)
  .component('UserRetrieve', UserRetrieve)
  .directive('perm', {
    beforeMount: (el, binding) => {
      const len = (binding.instance?.$route.meta.perms as string[]).filter(x => x === binding.arg).length;
      if (len === 0) {
        if (el.parentNode) {
          el.parentNode.removeChild(el);
        } else {
          el.style.display = 'none';
        }
      }
    }
  })
  .mount('#app');
