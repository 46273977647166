
import { defineComponent, inject, onUnmounted, PropType, reactive, ref, watch } from 'vue';
import { SelectOptions } from '@/types/form.config';
import { DataItem } from '@/types/app.global';
import { useInject } from '@/utils/inject';

export default defineComponent({
  name: 'SelectWidget',
  props: {
    opts: {
      type: Object as PropType<SelectOptions>,
      default: () => ({})
    },
    modelValue: [String, Number, Boolean]
  },
  emits: ['update:modelValue', 'itemChange'],
  setup(props, { emit }) {
    // 暴露变量
    const selectOpts = reactive(props.opts);
    const loading = ref(false);
    const value = ref(props.modelValue);
    const options = ref(selectOpts.options || []);
    const bindProp = {} as DataItem;

    // 本地变量
    const resetEvent = inject('resetEvent') as symbol;
    const { bus, message } = useInject();
    const dataSet = new Map<string | number, DataItem>();

    const onSelect = (val: string | number) => {
      selectOpts.listener?.(dataSet.get(val) || val);
      const retVal = selectOpts.evaluate?.(dataSet.get(val)!) || val;
      emit('update:modelValue', retVal);
      emit('itemChange', selectOpts.field);
    };

    const onVisibleChange = (visible: boolean) => {
      if (visible && selectOpts.remote) {
        const remote = selectOpts.remote;
        const params = selectOpts.remote.params || {};
        loading.value = true;

        remote.action(params).then(res => {
          loading.value = false;
          if (res.respType === 'OK') {
            const dataList = res.respData.totalElements || res.respData;
            options.value = dataList.map((x: DataItem) => {
              const label = x[selectOpts.mapper[1]];
              const val = x[selectOpts.mapper[0]];
              dataSet.set(val, x);
              return { label, value: val };
            });
          } else {
            message?.error(res.respMesg);
          }
        }, () => {
          loading.value = false;
        });
      }
    };

    const onReset = () => {
      value.value = undefined;
      dataSet.clear();
    };

    // 由于点击清除按钮不会触发handleChange事件，因此需要监听value并更新v-model的值
    watch(value, (nv) => {
      if (nv === undefined) {
        emit('update:modelValue', nv);
      }
    });

    // 提前加载远程数据
    if (selectOpts.fetch === true) {
      onVisibleChange(true);
    }

    onUnmounted(() => {
      if (resetEvent) {
        bus?.off(resetEvent);
      }
    });

    if (resetEvent) {
      bus?.on(resetEvent, onReset);
    }

    const initBindProp = () => {
      if (selectOpts.mode) {
        bindProp.mode = selectOpts.mode;
      }

      bindProp.placeholder = selectOpts.placeholder || '请选择';
      bindProp.allowClear = selectOpts.allowClear || true;
      bindProp.showArrow = selectOpts.showArrow || true;
      bindProp.disabled = selectOpts.disabled || false;
    };
    initBindProp();
    return { selectOpts, loading, value, options, bindProp, onSelect, onVisibleChange };
  }
});
