import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e0dc1bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"max-height":"620px","overflow-y":"auto"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  style: {"cursor":"pointer"}
}
const _hoisted_5 = ["data-clipboard-text", "onClick"]
const _hoisted_6 = ["title", "onClick", "data-clipboard-text"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_image_preview_group = _resolveComponent("a-image-preview-group")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_table, {
      ref: "tableDiv",
      "row-key": _ctx.tableOpts.rowKey || 'id',
      "data-source": _ctx.showList,
      loading: _ctx.tableOpts.loading,
      "row-selection": _ctx.tableOpts.selection || null,
      size: _ctx.tableOpts.size || 'small',
      columns: _ctx.showCols,
      pagination: false,
      tableLayout: "fixed",
      bordered: _ctx.tableOpts.bordered !== false
    }, {
      bodyCell: _withCtx(({ column, index, record, text }) => [
        (column.dataIndex === 'col-index')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(index + 1), 1)
            ], 64))
          : (column.editable || column.ability)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (column.editable === 'text')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                      _createVNode(_component_a_input, {
                        value: _ctx.dataList[index][column.dataIndex],
                        "onUpdate:value": ($event: any) => ((_ctx.dataList[index][column.dataIndex]) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]))
                  : (column.editable === 'number')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.dataList[index][column.dataIndex],
                          "onUpdate:value": ($event: any) => ((_ctx.dataList[index][column.dataIndex]) = $event),
                          min: column.min || 0,
                          max: column.max || Number.MAX_VALUE,
                          precision: column.scale || 0
                        }, null, 8, ["value", "onUpdate:value", "min", "max", "precision"])
                      ]))
                    : (column.ability === 'preview')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                          text
                            ? (_openBlock(), _createBlock(_component_a_image_preview_group, { key: 0 }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((Array.isArray(text) ? text : [text]), (src, index) => {
                                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                      (column.autosize === true)
                                        ? (_openBlock(), _createBlock(_component_a_image, {
                                            key: 0,
                                            style: _normalizeStyle({display: index === 0 ? 'inherit' : 'none'}),
                                            src: src,
                                            fallback: _ctx.constants.common.emptyImg
                                          }, null, 8, ["style", "src", "fallback"]))
                                        : (_openBlock(), _createBlock(_component_a_image, {
                                            key: 1,
                                            style: _normalizeStyle([{display: index === 0 ? 'inherit' : 'none'}, {"width":"50px","height":"50px"}]),
                                            src: src,
                                            fallback: _ctx.constants.common.emptyImg
                                          }, null, 8, ["style", "src", "fallback"]))
                                    ], 64))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 3,
                          class: _normalizeClass(column.ability === 'copy' ? 'copy' : undefined),
                          "data-clipboard-text": record[column.dataIndex],
                          onClick: ($event: any) => (_ctx.doCopy(column.ability === 'copy', column.label, $event))
                        }, _toDisplayString(text), 11, _hoisted_5)),
                (column.extra)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 4,
                      style: _normalizeStyle(column.extra.style)
                    }, _toDisplayString(record[column.extra.field]), 5))
                  : _createCommentVNode("", true)
              ], 64))
            : (column.group)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.groupMap.get(column.dataIndex), (col, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createVNode(_component_a_row, { type: "flex" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_col, {
                          flex: _ctx.clone(_ctx.groupMap.get(column.dataIndex)).sort((a, b) => b.title.length - a.title.length)[0].title.length * 15 + 10 + 'px'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(col.title) + " : ", 1)
                          ]),
                          _: 2
                        }, 1032, ["flex"]),
                        _createVNode(_component_a_col, {
                          flex: "auto",
                          style: {"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              title: col.ellipsis ? record[col.dataIndex] : undefined,
                              class: _normalizeClass(col.ability === 'copy' ? 'copy' : undefined),
                              onClick: ($event: any) => (_ctx.doCopy(col.ability === 'copy', col.label, $event)),
                              "data-clipboard-text": record[col.dataIndex]
                            }, _toDisplayString(record[col.dataIndex]), 11, _hoisted_6)
                          ]),
                          _: 2
                        }, 1024),
                        (index < _ctx.groupMap.get(column.dataIndex).length - 1)
                          ? (_openBlock(), _createBlock(_component_a_divider, {
                              key: 0,
                              dashed: "",
                              style: {"margin":"0"}
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
              : _createCommentVNode("", true),
        (column.dataIndex === 'col-cmd' && _ctx.commands.length > 0)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.commands, (c, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                (i > 0)
                  ? (_openBlock(), _createBlock(_component_a_divider, {
                      key: 0,
                      type: "vertical"
                    }))
                  : _createCommentVNode("", true),
                (c.confirm)
                  ? (_openBlock(), _createBlock(_component_a_popconfirm, {
                      key: 1,
                      title: "确认执行该操作?",
                      "ok-text": "是",
                      "cancel-text": "否",
                      onConfirm: ($event: any) => (c.action(_ctx.dataList[index]))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("a", null, _toDisplayString(c.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["onConfirm"]))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 2,
                      onClick: ($event: any) => (c.action(_ctx.dataList[index]))
                    }, _toDisplayString(c.label), 9, _hoisted_7))
              ], 64))
            }), 128))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["row-key", "data-source", "loading", "row-selection", "size", "columns", "bordered"])
  ]))
}