

import { defineComponent, PropType, reactive, ref, inject, onUnmounted } from 'vue';
import { CascadeOptions } from '@/types/form.config';
import { DataItem } from '@/types/app.global';
import { useInject } from '@/utils/inject';
import constants from '@/utils/constants';

/**
 * 级联下拉框
 * 1. 整体加载顺序
 * 1) 首次点击下拉控件，调用visibleChange函数，加载第一层级的数据
 * 2) 选中层级中（最后一层除外）的某一项，调用loadData函数，加载下一级菜单
 * 3) 选中最后层级的某一项，调用handleChange函数，将value的更新通知给外部
 *
 * 2. 级联下拉框只考虑远程数据的初始化，根据modelValue的初值，如果是数组，则调用initData函数，逐层级地加载数据，并显示结果
 * 3. 待完善功能
 * 1) 如果每个层级的查询方法一致，可以考虑增加repeat属性
 * 2) 当前层级如何自定义地获取上一层级的查询参数
 */
interface OptionItem {
  value: string | number
  label: string
  level: number
  loading: boolean
  isLeaf: boolean
  children?: OptionItem[]
}

export default defineComponent({
  name: 'CascadeWidget',
  props: {
    opts: {
      type: Object as PropType<CascadeOptions>,
      default: () => ({})
    },
    modelValue: [Array]
  },
  emits: ['update:modelValue', 'itemChange'],
  setup(props, { emit }) {
    // 暴露变量
    const cascadeOpts = reactive(props.opts);
    const value = ref((props.modelValue || []) as string[] | number[]);
    const dataSet = new Map<string | number, DataItem>();
    const topLevel = reactive({
      label: '',
      value: '',
      level: -1,
      loading: false,
      isLeaf: false,
      children: []
    } as OptionItem);

    // 本地变量
    const { bus, message } = useInject();
    const resetEvent = inject<symbol>(constants.event.reset);
    const localDepth = cascadeOpts.levels.length - 1; // 层级深度

    const getData = (option: OptionItem): Promise<OptionItem[] | undefined> | undefined => {
      const level = option.level + 1;
      const target = cascadeOpts.levels[level];
      const remote = target.remote;
      if (remote) {
        const params = {} as DataItem;

        if (remote.params) {
          for (const [k, v] of Object.entries(remote.params)) {
            // 默认情况，查询参数的值为#{value}，则为当前节点数据data的value属性
            if (v.toString() === '#{value}') {
              params[k] = option.value;
            } else {
              params[k] = v;
            }
            // 允许使用多查询参数，暂不实现
            // let value = v.toString();
            // if(value.startsWith("#{")){
            //     value = value.replace("#{", "").replace("}", "");
            //     params[k] = data.value[value];
            // }
            // console.log(k, v, value, params[k])
          }
        }

        option.loading = true;
        return remote.action(params).then(res => {
          option.loading = false;
          if (res.respType === 'OK') {
            option.children = res.respData.map((x: DataItem) => {
              const val = x[target.mapper[0]];
              dataSet.set(val, x);
              return { value: val, label: x[target.mapper[1]], isLeaf: level >= localDepth, level };
            });
          } else {
            message?.warning(res.respMesg);
          }
          return option.children; // 返回当前层级的children，作为链式调用then方法的参数
        }, () => {
          option.loading = false;
          return undefined;
        });
      }
      return undefined;
    };

    // 打开下拉框，加载一级节点数据
    const visibleChange = (visible: boolean) => {
      if (visible && topLevel.children?.length === 0) {
        getData(topLevel);
      }
    };

    // 加载子节点的事件
    const loadData = (list: OptionItem[]) => {
      getData(list[list.length - 1]); // 多级菜单，取最后一项
    };

    // 选中最后一级节点的事件，组装最终结果，并更新entity相关字段
    const handleChange = (arr: Array<string | number>) => {
      let retVal = {};
      let item = {} as DataItem;

      // 允许每一级都返回数据，但通常只有最后一级返回数据（省市区这种需要每一级都返回数据）
      arr.forEach((x, i) => {
        const data = dataSet.get(x);

        if (data) {
          const level = cascadeOpts.levels[i];
          if (level.evaluate) {
            item = level.evaluate(data);
          } else if (level.field) {
            item[level.field] = x;
          }
          retVal = Object.assign(retVal, item);
        }
      });

      emit('update:modelValue', retVal);
      emit('itemChange', cascadeOpts.field);
    };

    // 根据初始的value加载远程数据，填充控件的文本内容
    const initData = (option: OptionItem, index = 0) => {
      if (index < value.value.length) {
        getData(option)?.then(data => {
          const level = data?.find(x => x.value === value.value[index]);
          if (level) {
            initData(level, index + 1);
          }
        });
      }

      // 加载完所有层级，一定要手动调用handleChange方法，相当于选中叶子节点，更新外层entity的数据
      if (index === value.value.length - 1) {
        handleChange(value.value);
      }
    };

    if (Array.isArray(value.value) && value.value.length > 0) {
      initData(topLevel);
    }

    const onReset = () => {
      value.value = [];
      dataSet.clear();
    };

    onUnmounted(() => {
      if (resetEvent) {
        bus?.off(resetEvent);
      }
    });

    if (resetEvent) {
      bus?.on(resetEvent, onReset);
    }
    return { cascadeOpts, dataSet, value, topLevel, loadData, visibleChange, handleChange };
  }
});
