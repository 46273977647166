import axios from 'axios';
import { Resp } from '@/types/app.global';
import { getCookie } from '@/utils/storage';
import { message } from '@/utils/notification';

// const token = sessionStorage.getItem("token")
// if(token){
//     axios.defaults.headers.common['token'] = token.replace(/"/g, "")
// }
//
// axios.interceptors.response.use(res => {
//     return res
// // eslint-disable-next-line no-unused-vars
// }, error => {
//     ElMessage.error({message: '没有访问权限: ' + error.toString()});
//     // return Promise.reject(error)
// })

axios.interceptors.request.use((config) => {
  config.headers!.token = getCookie('ymd_token') || ''; // 请求头带上token
  config.headers!.alias = getCookie('ymd_alias') || ''; // 请求头带上alias
  return config;
}, (error) => Promise.reject(error));

axios.interceptors.response.use((res) => res, (error) => Promise.reject(error));

function get(url: string, data: any): Promise<Resp> {
  return req(url, data, 'get');
}

function post(url: string, data: any): Promise<Resp> {
  return req(url, data, 'post');
}

function postForm(url: string, data: any): Promise<Resp> {
  return req(url, data, 'postForm');
}

function postFormData(url: string, data: any): Promise<Resp> {
  return req(url, data, 'postFormData');
}

function req(url: string, data: any = {}, type = 'get'): Promise<Resp> {
  return new Promise((resolve, reject) => {
    let promise;
    if (type === 'get') {
      let dataStr = '';
      // Object.entries(data).forEach((k, v) => {
      //   if (v !== null && typeof v !== 'object') {
      //     dataStr += `&${k}=${v}`;
      //   }
      // });
      for (const [k, v] of Object.entries(data)) {
        if (v !== null && v !== undefined && v !== '' && typeof v !== 'object') {
          dataStr += `&${k}=${v}`;
        }
      }

      if (dataStr !== '') {
        url += '?' + dataStr.substring(1);
      }
      promise = axios.get(url);
    } else if (type === 'post') {
      promise = axios.post(url, data); // Json请求
    } else if (type === 'postForm') { // 表单提交
      promise = axios({
        url,
        data,
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        transformRequest: [function (params) {
          let ret = '';
          for (const it in params) {
            if (params[it] != null) {
              ret += '&' + encodeURIComponent(it) + '=' + encodeURIComponent(params[it]);
            }
          }
          return ret.substring(1);
        }],
      });
    } else if (type === 'postFormData') {
      const formData = new FormData();
      for (const k in data) {
        // 文件数组
        if (Array.isArray(data[k]) && typeof data[k][0] === 'object') {
          data[k].forEach((x: any) => {
            formData.append(k, x);
          });
        } else if (data[k] !== undefined) {
          formData.append(k, data[k]);
        }
      }
      promise = axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    } else {
      throw new Error('非法请求类型');
    }

    promise.then((res) => {
      if (res) {
        resolve(res.data);
      }
    }).catch((error) => {
      if (error.response && error.response.status >= 400) {
        message.error('网络请求错误');
      }
      reject(error);
    });
  });
}

export {
  get, post, postForm, postFormData,
};
