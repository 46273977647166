import { notification, message } from 'ant-design-vue';

declare type NotificationPlacement = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';

const notify = {
  success(msg: string, title?: string, placement?: NotificationPlacement): void {
    notification.success({ message: title || '成功', description: msg, placement: placement || 'bottomRight' });
  },
  warning(msg: string, title?: string, placement?: NotificationPlacement): void {
    notification.warning({ message: title || '警告', description: msg, placement: placement || 'bottomRight' });
  },
  info(msg: string, title?: string, placement?: NotificationPlacement): void {
    notification.info({ message: title || '提示', description: msg, placement: placement || 'bottomRight' });
  },
  error(msg: string, title?: string, placement?: NotificationPlacement): void {
    notification.error({ message: title || '失败', description: msg, placement: placement || 'bottomRight', duration: 0 });
  }
};

message.config({
  top: '300px'
});

export { notify, message };
