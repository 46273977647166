
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { useMutations } from '@/store/mixin';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'App',
  setup() {
    const store = useStore();
    const router = useRouter();
    const { setCurrTab, setTabList } = useMutations();
    const onCreated = () => {
      // TODO menuMap不能序列化，考虑改成数组
      window.addEventListener('beforeunload', () => {
        setCurrTab({});
        setTabList([]);
        router.replace('/');
        store.commit('CLEAR_ALL');
        localStorage.setItem('ymd_state', JSON.stringify(store.state));
      });

      const state = localStorage.getItem('ymd_state');
      if (state) {
        store.replaceState(Object.assign(store.state, JSON.parse(state)));
        localStorage.removeItem('ymd_state');
      }
    };
    onCreated();
    return { zhCN };
  }
});
