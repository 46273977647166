
import { computed, defineComponent, ref, watch, reactive, onUnmounted } from 'vue';
import { ApartmentOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons-vue';
import { DataItem, Menu, Tab } from '@/types/app.global';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useInject } from '@/utils/inject';
import { useMutations } from '@/store/mixin';
import { VIcon } from '@/utils/vnodes';
import constants from '@/utils/constants';
import FormModal from '@/components/composite/FormModal.vue';

export default defineComponent({
  name: 'Home',
  components: { FormModal, LogoutOutlined, UserOutlined, ApartmentOutlined, VIcon },
  setup() {
    // 本地变量
    const router = useRouter();
    const store = useStore();
    const { utils, bus, https } = useInject();
    const { setTabList, setCurrTab } = useMutations();
    const menuMap = computed(() => store.getters.menuMap);

    // 暴露变量
    const isDev = process.env.VUE_APP_PROFILE === constants.profile.dev;
    const selectedKeys = ref([] as string[]);
    const openKeys = ref([] as string[]);
    const menuList = computed(() => store.getters.menuList);
    const tabList = computed(() => store.getters.tabList);
    const currTab = computed(() => store.getters.currTab);
    const userMeta = store.getters.userMeta;
    const swapPwdOpts = reactive({
      title: '修改登录密码',
      visible: false,
      action: https?.member.swapLoginPwd,
      content: {
        cols: [
          {
            field: 'uid',
            visible: false,
            defVal: userMeta.uid
          },
          {
            field: 'oldPwd',
            label: '旧密码',
            required: true
          },
          {
            field: 'newPwd',
            label: '新密码',
            required: true
          }
        ]
      }
    });

    watch(currTab, nv => { selectedKeys.value = [nv.name]; });

    const handleClick = ({ key } : DataItem) => {
      selectedKeys.value = [key];
      const menu = menuMap.value.get(key);
      openTab(menu);
    };

    const openTab = (menu: Menu, params?: DataItem) => {
      if (menu && !tabList.value.find((x: DataItem) => x.name === menu.name)) {
        tabList.value.push({ name: menu.name, label: menu.label });
      }

      setCurrTab(menu);

      if (params) {
        router.replace({ name: menu.name, params });
      } else {
        router.replace({ name: menu.name });
      }
    };

    const onOpenChange = (keys: string[]) => {
      if (keys.length > 1) {
        openKeys.value = [keys[keys.length - 1]];
      } else if (keys.length === 0) {
        openKeys.value = [];
      }
    };

    const onNav = (info: any) => {
      const menu = menuMap.value.get(info.page);
      if (menu) {
        openTab(menu, info.params);
      }
    };

    const selectTab = (targetKey: string) => {
      if (currTab.value.name !== targetKey) {
        router.replace({ name: targetKey });
        setCurrTab(tabList.value.find((x: Tab) => x.name === targetKey));
      }
    };

    const closeTab = (type: string) => {
      if (type === 'all') {
        setTabList([]);
        setCurrTab({});
        router.replace('/');
      } else if (currTab.value) {
        setTabList([currTab.value]);
      }
    };

    const removeTab = (targetKey: string, action: string) => {
      if (action === 'remove') {
        const list = tabList.value.filter((x: Tab) => x.name !== targetKey);
        setTabList(list);

        if (tabList.value.length > 0) {
          setCurrTab(tabList.value[tabList.value.length - 1]);
          router.replace({ name: currTab.value.name });
        }
        // 跳转首页
        else {
          setCurrTab({});
          router.replace('/');
        }
      }
    };

    const logout = () => {
      utils?.storage.clearLoginInfo();
      router.replace('/login');
    };

    // 页面跳转的回调，一般携带参数
    const onPageNav = (info: any) => {
      const menu = menuMap.value.get(info.page);

      if (menu) {
        openTab(menu, info.params);
      }
    };

    bus?.on(constants.event.pageNav, onPageNav);

    onUnmounted(() => {
      bus?.off(constants.event.pageNav);
    });
    return { menuList, tabList, currTab, userMeta, selectedKeys, openKeys, swapPwdOpts, isDev, handleClick, openTab, onOpenChange, onNav, selectTab, closeTab, removeTab, logout };
  }
});
