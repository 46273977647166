import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, _mergeProps({
      visible: _ctx.modalOpts.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalOpts.visible) = $event)),
      "confirm-loading": _ctx.modalOpts.loading
    }, _ctx.bindProp, {
      "ok-text": "确认",
      "cancel-text": "取消",
      destroyOnClose: "",
      onOk: _ctx.onConfirm
    }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content")
      ]),
      _: 3
    }, 16, ["visible", "confirm-loading", "onOk"])
  ]))
}