import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_retrieve_input = _resolveComponent("retrieve-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_retrieve_input, {
      opts: _ctx.retrieveOpts,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, null, 8, ["opts", "modelValue"])
  ]))
}