
import { defineComponent, PropType, reactive, ref, watch, inject, isProxy, toRaw, onUnmounted } from 'vue';
import { cloneDeep } from 'lodash';
import { FormItemOptions, FormOptions } from '@/types/form.config';
import { DataItem } from '@/types/app.global';
import { useInject } from '@/utils/inject';
import constants from '@/utils/constants';

import SelectWidget from '@/components/form/SelectWidget.vue';
import SwitchWidget from '@/components/form/SwitchWidget.vue';
import CascadeWidget from '@/components/form/CascadeWidget.vue';
import TimeWidget from '@/components/form/TimeWidget.vue';
import DateWidget from '@/components/form/DateWidget.vue';
import RadioWidget from '@/components/form/RadioWidget.vue';
import CheckboxWidget from '@/components/form/CheckboxWidget.vue';
import UploadWidget from '@/components/form/UploadWidget.vue';

export default defineComponent({
  name: 'FormWidget',
  components: { UploadWidget, CheckboxWidget, RadioWidget, DateWidget, TimeWidget, CascadeWidget, SwitchWidget, SelectWidget },
  props: {
    opts: {
      type: Object as PropType<FormOptions>,
      default: () => ({})
    }
  },
  setup(props) {
    // 暴露变量
    const formRef = ref();
    const formOpts = reactive(cloneDeep(props.opts));
    const divides = [] as FormItemOptions[][];
    let entity = reactive<DataItem>({});
    const validEntity = reactive<DataItem>({});
    const rules = ref<DataItem>({});

    // 本地变量
    const { bus } = useInject();
    const modalConfirm = inject<symbol>(constants.event.modalConfirm);
    const ruleMap = new Map<string, any>();
    const rawEntity: DataItem = {};
    let validFailure = false;

    const initEntity = () => {
      if (formOpts.entity) {
        Object.assign(rawEntity, formOpts.entity);
      }

      formOpts.cols.forEach(col => {
        if (rawEntity[col.field] === null || rawEntity[col.field] === undefined) {
          rawEntity[col.field] = col.defVal;
        }
      });
      entity = reactive(cloneDeep(rawEntity));

      watch(entity, () => {
        if (validFailure) {
          formRef.value.clearValidate();
          validFailure = false;
        }
      });
    };

    const initRules = () => {
      formOpts.cols.forEach(col => {
        // 必填
        const rule = [];
        if (col.required) {
          rule.push({ required: true, message: `请填写${col.label}`, trigger: 'change' });
        }

        if (rule.length > 0) {
          ruleMap.set(col.field, rule);
        }
      });

      // 初始化当前可见控件的校验规则
      updateRules();
    };

    // 更新校验规则
    const updateRules = (col?: FormItemOptions) => {
      // 单个字段，添加或移除校验规则
      if (col) {
        if (col.visible !== false) {
          rules.value[col.field] = ruleMap.get(col.field);
        } else {
          // 对于隐藏的字段，移除校验规则，entity对应的值也要重置
          delete rules.value[col.field];
          // 已经监听了entity的变化，会自行移除可能存在的校验结果
          entity[col.field] = rawEntity[col.field];
        }
      }
      // 所有字段，用于初始化
      else {
        const tmp: DataItem = {};
        formOpts?.cols.filter(c => c.visible !== false).forEach(x => {
          tmp[x.field] = ruleMap.get(x.field);
        });
        rules.value = tmp;
      }
    };

    const initOpts = () => {
      // 1. 处理列的配置
      formOpts.cols.forEach(col => {
        // 1.1 添加表单项值变更事件
        if (col.changeNotify) {
          watch(() => entity[col.field], () => {
            formOpts.handleValueChange?.(col.field, entity, formOpts.cols);
          });
        }

        // 1.2 根据visible的变化，动态地更新校验规则
        if (col.required === true) {
          watch(() => col.visible, () => {
            updateRules(col);
          });
        }

        // 1.3 计算每个控件的disabled的最终值（初始为布尔或字符串数组，最终为布尔）
        if (Array.isArray(col.disabled)) {
          let flag = false;
          // 如果disabled属性为string[]类型，如果数组元素与code相等，说明该操作下的控件不可用
          col.disabled.forEach(code => {
            if (code === formOpts.name) {
              flag = true;
            }
          });
          col.disabled = flag;
        } else if (typeof col.disabled === 'function') {
          col.disabled = col.disabled();
        }

        // 1.4 计算每个控件的visible的最终值（逻辑与disabled相同）
        if (Array.isArray(col.visible)) {
          let flag = false;
          col.visible.forEach(code => {
            if (code === formOpts.name) {
              flag = true;
            }
          });
          col.visible = flag;
        }
      });

      // 2. 表单拆分为左右两列
      if (formOpts.divide) {
        const index = formOpts.cols.findIndex(col => col.field === formOpts.divide);
        divides.push(formOpts.cols.slice(0, index), formOpts.cols.slice(index));
      } else {
        divides.push(formOpts.cols);
      }
    };

    const onModalSubmit = (callback: unknown) => {
      // 根据entity组装data
      let params = {} as DataItem;
      const fields = new Set<string>();
      for (const [k, v] of Object.entries(entity)) {
        // 对object类型进行展开（数组除外）
        if (v !== null && typeof v === 'object' && !Array.isArray(v)) {
          params = { ...params, ...v };
          Object.keys(v).forEach(x => fields.add(x));
        }
        // 简单类型和数组类型直接赋值
        else if (!fields.has(k)) {
          // 由于entity被reactive方法包装，对象类型的属性都是Proxy，因此需要将数组转成普通类型
          if (isProxy(v)) {
            params[k] = toRaw(v);
          } else {
            params[k] = v;
          }
        }
      }

      // 非空校验时对象类型不能通过，因此需要将对象转换成其他值
      for (const [k, v] of Object.entries(params)) {
        if (v !== undefined && v !== null) { // undefined和null都要排除掉
          if (typeof v === 'object') {
            validEntity[k] = 'actual-value-object';
          } else {
            validEntity[k] = v + '';
          }
        }
      }

      formRef.value.validate().then(() => {
        if (typeof callback === 'function') {
          // 计算最终返回结果
          params = formOpts.evaluate?.(params) || params;
          callback(params);
        }
      }).catch((e: any) => {
        validFailure = true;
      });
    };

    const extractData = (callback: unknown) => {
      onModalSubmit(callback);
    };

    initEntity();
    initOpts();
    initRules();

    if (modalConfirm) {
      bus?.on(modalConfirm, onModalSubmit);
    }

    onUnmounted(() => {
      if (modalConfirm) {
        bus?.off(modalConfirm);
      }
    });

    const expose = { extractData };
    return { formRef, formOpts, validEntity, entity, rules, divides, expose };
  }
});
