import { get, postForm, postFormData } from '@/https/req';
import { DataItem } from '@/types/app.global';

const BASE_URL = process.env.VUE_APP_BASE_URL;

export const pageProduct = (data: DataItem) => get(`${BASE_URL}/ymd/backend/mall/product/page`, data);
export const createProduct = (data: DataItem) => postFormData(`${BASE_URL}/ymd/backend/mall/product/create`, data);
export const updateProduct = (data: DataItem) => postFormData(`${BASE_URL}/ymd/backend/mall/product/update`, data);

export const pageDistribution = (data: DataItem) => get(`${BASE_URL}/ymd/backend/mall/distribution/page`, data);
export const listDistributionDetail = (data: DataItem) => get(`${BASE_URL}/ymd/backend/mall/distribution/detail/list`, data);
export const listDistributionFlow = (data: DataItem) => get(`${BASE_URL}/ymd/backend/mall/distribution/flow/list`, data);
export const handleDistributionDetail = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/mall/distribution/detail/handle`, data);

export const pageProductOrder = (data: DataItem) => get(`${BASE_URL}/ymd/backend/mall/productOrder/page`, data);
export const listProductOrderDetail = (data: DataItem) => get(`${BASE_URL}/ymd/backend/mall/productOrder/detail/list`, data);

export const listLogisticCom = (data: DataItem) => get(`${BASE_URL}/ymd/backend/mall/logistic/com/list`, data);

export const pageOfflinePayment = (data: DataItem) => get(`${BASE_URL}/ymd/backend/mall/offlinepayment/page`, data);
export const auditOfflinePayment = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/mall/offlinepayment/audit`, data);

export const getAddress = (data: DataItem) => get(`${BASE_URL}/ymd/backend/mall/address/info`, data);

export const listEnum = (data: DataItem) => get(`${BASE_URL}/ymd/backend/mall/enum/list`, data);
