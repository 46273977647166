
import { defineComponent, inject, onUnmounted, PropType, reactive, ref } from 'vue';
import { useInject } from '@/utils/inject';
import { RetrieveInputOptions } from '@/types/table.config';
import { DataItem } from '@/types/app.global';

import RetrieveModal from '@/components/retrieve/RetrieveModal.vue';
import { CloseCircleFilled } from '@ant-design/icons-vue';

export default defineComponent({
  name: 'RetrieveInput',
  components: { RetrieveModal, CloseCircleFilled },
  props: {
    opts: {
      type: Object as PropType<RetrieveInputOptions>,
      default: () => ({})
    },
    modelValue: [String]
  },
  setup(props, { emit }) {
    const retrieveOpts = reactive(props.opts);
    const value = ref(props.modelValue || '');
    const resetEvent = inject('resetEvent') as symbol;
    const { bus } = useInject();

    const onSelect = (data: DataItem) => {
      // 显示在输入框的内容
      value.value = data[retrieveOpts.mapper[1]];
      // 隐藏的实际值
      const retVal = retrieveOpts.evaluate?.(data) || data[retrieveOpts.mapper[0]];
      emit('update:modelValue', retVal);
    };

    const onClear = () => {
      value.value = '';
      emit('update:modelValue', '');
    };

    onUnmounted(() => {
      if (resetEvent) {
        bus?.off(resetEvent);
      }
    });

    if (resetEvent) {
      bus?.on(resetEvent, onClear);
    }
    return { retrieveOpts, value, onSelect, onClear };
  }
});
