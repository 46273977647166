
import { defineComponent, PropType, ref, reactive, inject, onUnmounted } from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/zh-cn';
import { useInject } from '@/utils/inject';
import { DateOptions } from '@/types/form.config';
import { DataItem } from '@/types/app.global';
import constants from '@/utils/constants';

export default defineComponent({
  name: 'DateWidget',
  props: {
    opts: {
      type: Object as PropType<DateOptions>,
      default: () => ({})
    },
    modelValue: [String]
  },
  setup(props, { emit }) {
    // 暴露变量
    const dateOpts = reactive(props.opts);
    const value = ref<Dayjs>();
    const bindProp: DataItem = {};

    // 本地变量
    let pattern: string;
    const { bus } = useInject();
    const resetEvent = inject<symbol>(constants.event.reset);

    if (dateOpts.type === 'year') {
      pattern = dateOpts?.pattern || 'YYYY';
      bindProp.placeholder = dateOpts.placeholder || '选择年份';
    }
    else if (dateOpts.type === 'month') {
      pattern = dateOpts?.pattern || 'YYYY-MM';
      bindProp.placeholder = dateOpts.placeholder || '选择月份';
    }
    else {
      pattern = dateOpts?.pattern || 'YYYY-MM-DD';
      bindProp.placeholder = dateOpts.placeholder || '选择日期';
    }
    bindProp.disabled = dateOpts.disabled;
    bindProp.format = pattern;

    // 如果存在初值，则初始化value（需要事先获取格式pattern）
    if (props.modelValue) {
      value.value = dayjs(props.modelValue, pattern);
    }

    const onChange = (date: Dayjs, dateStr: string) => {
      emit('update:modelValue', dateStr);
      emit('itemChange', dateOpts.field);
    };

    const onReset = () => {
      value.value = undefined;
    };

    if (resetEvent) {
      bus?.on(resetEvent, onReset);
    }

    onUnmounted(() => {
      if (resetEvent) {
        bus?.off(resetEvent);
      }
    });

    return { dateOpts, value, bindProp, onChange };
  }
});
