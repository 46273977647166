import * as sys from './modules/sys';
import * as market from './modules/market';
import * as device from './modules/device';
import * as mall from './modules/mall';
import * as member from './modules/member';
import * as payment from './modules/payment';
import * as stat from './modules/stat';
import * as facade from './modules/facade';

export const https = {
  sys, market, device, mall, member, payment, stat, facade
};
