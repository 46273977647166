
import { defineComponent, PropType, reactive, ref } from 'vue';
import { UploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { UploadOptions } from '@/types/form.config';
import { DataItem } from '@/types/app.global';

interface FileInfo {
  lastModified: number
  name: string
  originFileObj: File
  percent: number
  size: number
  thumbUrl: string
  type: string
  uid: string
}

export default defineComponent({
  name: 'UploadWidget',
  components: {
    UploadOutlined, LoadingOutlined, PlusOutlined
  },
  props: {
    opts: {
      type: Object as PropType<UploadOptions>,
      default: () => ({})
    },
    modelValue: [Object]
  },
  emits: ['update:modelValue', 'itemChange'],
  setup(props, { emit }) {
    const uploadOpts = reactive(props.opts);
    const fileList = reactive([]);
    const loading = ref(false);

    const beforeUpload = () => false;

    const handleChange = (data: {file: File, fileList: FileInfo[]}) => {
      const retVal = {} as DataItem;
      if (uploadOpts.multiple === true) {
        retVal[uploadOpts.field] = data.fileList.map(x => x.originFileObj);
      } else {
        retVal[uploadOpts.field] = data.file;
      }
      emit('update:modelValue', retVal);
      emit('itemChange', uploadOpts.field);
    };
    return { fileList, loading, beforeUpload, handleChange };
  }
});
