const common = {
  enableStr: [
    { value: '0', label: '禁用' },
    { value: '1', label: '启用' }
  ],
  enableNum: [
    { value: 0, label: '禁用' },
    { value: 1, label: '启用' }
  ],
  enableBool: [
    { value: false, label: '禁用' },
    { value: true, label: '启用' }
  ],
  approveStatus: [
    { value: '0', label: '拒绝' },
    { value: '1', label: '同意' }
  ],
  // 三值状态
  tripleStatus: [
    { value: '0', label: '初始' },
    { value: '1', label: '成功' },
    { value: '2', label: '失败' }
  ]
};

const sys = {
  // 角色类型
  roleTypes: [
    { value: '1', label: '系统' },
    { value: '2', label: '员工' },
    { value: '3', label: '代理' }
  ],
  // 菜单类型
  menuTypes: [
    { value: '1', label: '模块' },
    { value: '2', label: '页面' },
    { value: '3', label: '按钮' }
  ],
};

const market = {
  // 激活返现标识
  activateRewardFlag: [
    { value: '1', label: '正常返现' },
    { value: '2', label: '无返现' }
  ],
  // 周期单位
  termUnitTypes: [
    { value: '1', label: '天' },
  ],
  // 返现对象
  backPersonTypes: [
    { value: '3', label: '代理' },
  ],
  // 考核状态
  checkStatus: [
    { value: '1', label: '考核中' },
    { value: '3', label: '考核成功' },
    { value: '4', label: '考核失败' }
  ],
  // 结算价变更状态
  profitModifyStatus: [
    { value: '1', label: '待处理' },
    { value: '2', label: '撤销' },
    { value: '3', label: '同意' },
    { value: '4', label: '拒绝' },
    { value: '9', label: '失效' }
  ],
  // 结算价变更明细状态
  profitModifyDetailStatus: [
    { value: '1', label: '待处理' },
    { value: '3', label: '成功' },
    { value: '4', label: '失败' },
    { value: '9', label: '无需处理' }
  ],
  // 流量卡订单状态
  simCardOrderStatus: [
    { value: '1', label: '初始化' },
    { value: '2', label: '成功' },
    { value: '3', label: '关闭' },
  ],
  // 流量卡变更类型
  simCardModifyType: [
    { value: '1', label: '后台' },
    { value: '2', label: '直接下级' },
    { value: '3', label: '跨级' },
  ]
};

const device = {
  // 入库方式
  stockType: [
    { value: '1', label: '连续号段' },
    { value: '2', label: '非连续号段' }
  ],
  // 机具标识
  markTypes: [
    { value: '0', label: '测试' },
    { value: '1', label: '正式' }
  ],
  // 机具状态
  deviceStatus: [
    { value: '0', label: '初始' },
    { value: '1', label: '入库' },
    { value: '2', label: '出库' },
  ],
  // 绑定状态
  bindStatus: [
    { value: '0', label: '未绑定' },
    { value: '1', label: '已绑定' },
    { value: '2', label: '已解绑' },
  ],
  // 激活状态
  activateStatus: [
    { value: '0', label: '未激活' },
    { value: '1', label: '激活成功' },
    { value: '2', label: '激活失败' },
  ],
  // 机具品牌
  backerNo: [
    { value: '0', label: '全渠道' },
    { value: '10', label: '付临门' },
    { value: '20', label: '乐刷' }
  ],
  // 机具品牌（不能选择全部）
  backerNo2: [
    { value: '10', label: '付临门' },
    { value: '20', label: '乐刷' }
  ],
  // 冻结类型
  freezeFlag: [
    { value: 'Y', label: '冻结版' },
    { value: 'N', label: '非冻结版' },
  ],
  // 划拨类型
  allocType: [
    { value: '1', label: '入库' },
    { value: '2', label: '出库' },
    { value: '3', label: '划拨' },
    { value: '4', label: '撤回' },
  ],
  // 划拨记录状态
  allocStatus: [
    { value: '0', label: '初始' },
    { value: '1', label: '待定' },
    { value: '2', label: '成功' },
    { value: '3', label: '失败' }
  ],
};

const mall = {
  // 商品类型
  productType: [
    { value: '1', label: '大POS' },
    { value: '2', label: '电签POS' },
  ],
  // 商品订单状态
  productOrderStatus: [
    { value: '0', label: '支付中' },
    { value: '1', label: '支付失败' },
    { value: '4', label: '支付成功' },
    { value: '99', label: '状态未知' }
  ],
  // 商品状态
  productStatus: [
    { value: '0', label: '初始' },
    { value: '1', label: '上架' },
    { value: '2', label: '下架' },
  ],
  // 支付方式
  payType: [
    { value: '1', label: '支付宝' },
    { value: '2', label: '线下' },
  ],
  // 0-无效 1-待配货 2-部分配货 3-配货完毕
  distributionStatus: [
    { value: '0', label: '无效' },
    { value: '1', label: '待配货' },
    { value: '2', label: '部分配货' },
    { value: '3', label: '配货完毕' }
  ],
  // 线下打款状态
  offlinePaymentStatus: [
    { value: '0', label: '待审核' },
    { value: '1', label: '审核失败' },
    { value: '4', label: '审核成功' }
  ],
  // 线下打款审核状态
  offlinePaymentAuditType: [
    { value: '1', label: '驳回' },
    { value: '4', label: '通过' },
  ],
  // 线下打款类型
  offlinePaymentType: [
    { value: '1', label: '打款' },
    { value: '2', label: '分润' },
    { value: '3', label: '借款' },
    { value: '4', label: '奖励' }
  ]
};

const member = {
  // 用户类型（部分）
  userType: [
    { value: '7', label: '直签代理' },
    { value: '8', label: '间签代理' },
  ],
  // 实名状态
  realStatus: [
    { value: '0', label: '未实名' },
    { value: '1', label: '已实名' },
  ],
  // 登录状态
  liveStatus: [
    { value: '0', label: '正常' },
    { value: '1', label: '用户注销' },
    { value: '2', label: '平台注销' },
    { value: '9', label: '永久注销' }
  ]
};

const stat = {
  // 收益类别
  pftSuitType: [
    { value: '11', label: '交易分润' },
    { value: '12', label: '激活返现' },
    { value: '13', label: '提货补贴' },
    { value: '14', label: '绩效考核' },
    { value: '15', label: '流量分成' },
  ],
  // 业绩类别
  perfSuitType: [
    { value: 'AGENT', label: '代理推广数' },
    { value: 'BANIA', label: '商户进件数' },
    { value: 'BIND', label: '绑定机具数' },
    { value: 'LIVE', label: '激活机具数' },
    { value: 'PASS', label: '达标机具数' },
    { value: 'SHOP', label: '采购机具数' },
    { value: 'ROAM', label: '划拨机具数' }
  ],
  // 交易类别
  txnSuitType: [
    { value: '0', label: '全部交易' },
    { value: 'D0', label: 'D0交易' },
    { value: 'T1', label: 'T1交易' },
    { value: 'D0.GQR', label: 'D0聚合码' },
    { value: 'D0.ZFB', label: 'D0支付宝' },
    { value: 'T1.WX', label: 'T1微信' },
    { value: 'T1.SHK', label: 'T1刷卡' },
    { value: 'T1.YSF', label: 'T1云闪付' },
    { value: 'T1.GQR', label: 'T1聚合码' },
    { value: 'T1.ZFB', label: 'T1支付宝' },
  ]
};

const mapper = {
  common,
  sys,
  market,
  device,
  mall,
  member,
  stat
};

export default mapper;
