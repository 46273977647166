
import { defineComponent, PropType } from 'vue';
import ModalWidget from '@/components/widget/ModalWidget.vue';
import FormWidget from '@/components/form/FormWidget.vue';
import { FormOptions } from '@/types/form.config';
import { ModalOptions } from '@/types/container.config';

export default defineComponent({
  name: 'FormModal',
  components: { FormWidget, ModalWidget },
  props: {
    opts: {
      type: Object as PropType<ModalOptions<FormOptions>>,
      default: () => ({})
    }
  }
});
