
import { defineComponent, inject, onUnmounted, PropType, ref, reactive } from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/zh-cn';
import { useInject } from '@/utils/inject';
import { DateOptions } from '@/types/form.config';
import { DataItem } from '@/types/app.global';
import constants from '@/utils/constants';

export default defineComponent({
  name: 'TimeWidget',
  props: {
    opts: {
      type: Object as PropType<DateOptions>,
      default: () => ({})
    },
    modelValue: [String]
  },
  setup(props, { emit }) {
    const timeOpts = reactive(props.opts);
    const value = ref<Dayjs>();
    const bindProp: DataItem = {};

    // 本地变量
    const { bus } = useInject();
    const resetEvent = inject(constants.event.reset) as symbol;
    const pattern = timeOpts.pattern || 'HH:mm:ss';

    if (props.modelValue) {
      value.value = dayjs(props.modelValue, pattern);
    }
    bindProp.pattern = pattern;
    bindProp.disabled = timeOpts?.disabled;
    bindProp.placeholder = '选择时间';

    const onChange = (time: Dayjs, timeStr: string) => {
      emit('update:modelValue', timeStr);
      emit('itemChange', timeOpts.field);
    };

    const onReset = () => {
      value.value = undefined;
    };

    if (resetEvent) {
      bus?.on(resetEvent, onReset);
    }

    onUnmounted(() => {
      if (resetEvent) {
        bus?.off(resetEvent);
      }
    });
    return { value, timeOpts, bindProp, onChange };
  }
});
