import { get } from '@/https/req';
import { DataItem } from '@/types/app.global';

const BASE_URL = process.env.VUE_APP_BASE_URL;

export const infoTxnDaily = (data: DataItem) => get(`${BASE_URL}/ymd/backend/stat/txn/daily/info`, data);
export const infoTxnMonthly = (data: DataItem) => get(`${BASE_URL}/ymd/backend/stat/txn/monthly/info`, data);
export const infoTxnTotal = (data: DataItem) => get(`${BASE_URL}/ymd/backend/stat/txn/total/info`, data);

export const infoPftDaily = (data: DataItem) => get(`${BASE_URL}/ymd/backend/stat/pft/daily/info`, data);
export const infoPftMonthly = (data: DataItem) => get(`${BASE_URL}/ymd/backend/stat/pft/monthly/info`, data);
export const infoPftTotal = (data: DataItem) => get(`${BASE_URL}/ymd/backend/stat/pft/total/info`, data);

export const infoPerfDaily = (data: DataItem) => get(`${BASE_URL}/ymd/backend/stat/perf/daily/info`, data);
export const infoPerfMonthly = (data: DataItem) => get(`${BASE_URL}/ymd/backend/stat/perf/monthly/info`, data);
export const infoPerfTotal = (data: DataItem) => get(`${BASE_URL}/ymd/backend/stat/perf/total/info`, data);
