import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_cascader = _resolveComponent("a-cascader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_cascader, {
      value: _ctx.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      options: _ctx.topLevel.children,
      placeholder: _ctx.cascadeOpts.placeholder || '请选择',
      style: {"width":"200px","max-width":"300px"},
      disabled: _ctx.cascadeOpts.disabled,
      loadData: _ctx.loadData,
      onChange: _ctx.handleChange,
      onPopupVisibleChange: _ctx.visibleChange
    }, null, 8, ["value", "options", "placeholder", "disabled", "loadData", "onChange", "onPopupVisibleChange"])
  ]))
}