import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 12 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_select_widget = _resolveComponent("select-widget")!
  const _component_cascade_widget = _resolveComponent("cascade-widget")!
  const _component_switch_widget = _resolveComponent("switch-widget")!
  const _component_radio_widget = _resolveComponent("radio-widget")!
  const _component_checkbox_widget = _resolveComponent("checkbox-widget")!
  const _component_date_widget = _resolveComponent("date-widget")!
  const _component_time_widget = _resolveComponent("time-widget")!
  const _component_upload_widget = _resolveComponent("upload-widget")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_user_retrieve = _resolveComponent("user-retrieve")!
  const _component_a_form_item_rest = _resolveComponent("a-form-item-rest")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form, {
      ref: "formRef",
      model: _ctx.validEntity,
      rules: _ctx.rules,
      "label-col": {span: _ctx.formOpts.labelSpan || 6},
      "wrapper-col": {style: {width: '240px'}}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, { gutter: 20 }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.divides, (divide, index) => {
              return (_openBlock(), _createBlock(_component_a_col, {
                key: index,
                span: 24 / _ctx.divides.length
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(divide, (col, n) => {
                    return _withDirectives((_openBlock(), _createBlock(_component_a_form_item, {
                      key: n,
                      label: col.label,
                      name: col.field,
                      disabled: col.disabled
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          style: {"display":"none"},
                          value: "1"
                        }),
                        _createVNode(_component_a_form_item_rest, null, {
                          default: _withCtx(() => [
                            (col.type === 'select')
                              ? (_openBlock(), _createBlock(_component_select_widget, {
                                  key: 0,
                                  opts: col,
                                  modelValue: _ctx.entity[col.field],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                                }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                              : (col.type === 'cascade')
                                ? (_openBlock(), _createBlock(_component_cascade_widget, {
                                    key: 1,
                                    opts: col,
                                    modelValue: _ctx.entity[col.field],
                                    "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                                  }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                                : (col.type === 'switch')
                                  ? (_openBlock(), _createBlock(_component_switch_widget, {
                                      key: 2,
                                      opts: col,
                                      modelValue: _ctx.entity[col.field],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                                    }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                                  : (col.type === 'radio')
                                    ? (_openBlock(), _createBlock(_component_radio_widget, {
                                        key: 3,
                                        opts: col,
                                        modelValue: _ctx.entity[col.field],
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                                      }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                                    : (col.type === 'checkbox')
                                      ? (_openBlock(), _createBlock(_component_checkbox_widget, {
                                          key: 4,
                                          opts: col,
                                          modelValue: _ctx.entity[col.field],
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                                        }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                                      : (col.type === 'date' || col.type === 'month' || col.type === 'year')
                                        ? (_openBlock(), _createBlock(_component_date_widget, {
                                            key: 5,
                                            opts: col,
                                            modelValue: _ctx.entity[col.field],
                                            "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                                          }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                                        : (col.type === 'time')
                                          ? (_openBlock(), _createBlock(_component_time_widget, {
                                              key: 6,
                                              opts: col,
                                              modelValue: _ctx.entity[col.field],
                                              "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                                            }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                                          : (col.type === 'upload' || col.type === 'upload-pic-card')
                                            ? (_openBlock(), _createBlock(_component_upload_widget, {
                                                key: 7,
                                                opts: col,
                                                modelValue: _ctx.entity[col.field],
                                                "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                                              }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                                            : (col.type === 'number')
                                              ? (_openBlock(), _createBlock(_component_a_input_number, {
                                                  key: 8,
                                                  value: _ctx.entity[col.field],
                                                  "onUpdate:value": ($event: any) => ((_ctx.entity[col.field]) = $event),
                                                  min: col.min || 0,
                                                  max: col.max || Number.MAX_VALUE,
                                                  precision: col.scale || 0,
                                                  disabled: col.disabled,
                                                  style: {"width":"200px"}
                                                }, null, 8, ["value", "onUpdate:value", "min", "max", "precision", "disabled"]))
                                              : (col.type === 'textarea')
                                                ? (_openBlock(), _createBlock(_component_a_textarea, {
                                                    key: 9,
                                                    opts: col,
                                                    value: _ctx.entity[col.field],
                                                    "onUpdate:value": ($event: any) => ((_ctx.entity[col.field]) = $event),
                                                    disabled: col.disabled,
                                                    style: {"width":"300px"}
                                                  }, null, 8, ["opts", "value", "onUpdate:value", "disabled"]))
                                                : (col.type === 'agent')
                                                  ? (_openBlock(), _createBlock(_component_user_retrieve, {
                                                      key: 10,
                                                      opts: col,
                                                      modelValue: _ctx.entity[col.field],
                                                      "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                                                    }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                                                  : (_openBlock(), _createBlock(_component_a_input, {
                                                      key: 11,
                                                      value: _ctx.entity[col.field],
                                                      "onUpdate:value": ($event: any) => ((_ctx.entity[col.field]) = $event),
                                                      disabled: col.disabled,
                                                      style: {"width":"300px"}
                                                    }, null, 8, ["value", "onUpdate:value", "disabled"])),
                            (col.ext)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.entity[col.field].ext), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["label", "name", "disabled"])), [
                      [_vShow, col.visible !== false]
                    ])
                  }), 128))
                ]),
                _: 2
              }, 1032, ["span"]))
            }), 128))
          ]),
          _: 1
        }),
        _renderSlot(_ctx.$slots, "tips"),
        (_ctx.formOpts.tips)
          ? (_openBlock(), _createBlock(_component_a_alert, {
              key: 0,
              message: typeof _ctx.formOpts.tips.msg === 'function' ? _ctx.formOpts.tips.msg() : _ctx.formOpts.tips.msg,
              description: _ctx.formOpts.tips.desc,
              type: _ctx.formOpts.tips.type || 'info',
              "show-icon": ""
            }, null, 8, ["message", "description", "type"]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["model", "rules", "label-col"])
  ]))
}