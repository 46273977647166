
import { defineComponent, PropType, ref } from 'vue';
import { DataItem } from '@/types/app.global';
import { SwitchOptions } from '@/types/form.config';
import { SelectKeyType } from '@/types/types.config';

export default defineComponent({
  name: 'SwitchWidget',
  props: {
    opts: {
      type: Object as PropType<SwitchOptions>,
      default: () => ({})
    },
    modelValue: [String, Number, Boolean]
  },
  emits: ['update:modelValue', 'itemChange'],
  setup(props, { emit }) {
    const switchOpts = props.opts as SwitchOptions;
    const checked = ref(props.modelValue !== null ? props.modelValue : switchOpts.options[1].value);
    const bindProp = {} as DataItem;

    const onToggle = (val: SelectKeyType) => {
      emit('update:modelValue', val);
      emit('itemChange', switchOpts.field);
    };

    const onClick = () => {
      switchOpts.action?.();
    };

    // 如果没有默认值，需要更新modelValue，将默认值设置为真
    if (!props.modelValue) {
      onToggle(props.opts.options[1].value);
    }

    if (switchOpts.showText) {
      bindProp.unCheckedChildren = switchOpts.options[0].label;
      bindProp.checkedChildren = switchOpts.options[1].label;
    }
    bindProp.unCheckedValue = switchOpts.options[0].value;
    bindProp.checkedValue = switchOpts.options[1].value;
    return { switchOpts, checked, bindProp, onToggle, onClick };
  }
});
