import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_time_picker = _resolveComponent("a-time-picker")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_time_picker, _mergeProps({
      value: _ctx.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, _ctx.bindProp, { onChange: _ctx.onChange }), null, 16, ["value", "onChange"])
  ]))
}