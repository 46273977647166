import { createStore } from 'vuex';
import { RouteRecordRaw } from 'vue-router';
import { Menu, State } from '@/types/app.global';
import constants from '@/utils/constants';

export default createStore({
  state: new State(),
  mutations: {
    SET_USER_META: (state, userMeta) => {
      state.userMeta = userMeta;
    },
    SET_MENU_LIST: (state, menuList: Menu[]) => {
      const list = menuList.filter(x => x.pid === 0);
      const map = new Map<string, Menu>();
      list.forEach(x => {
        const menus = menuList.filter(y => y.type === constants.enums.menuType.page && x.id === y.pid);
        if (menus.length > 0) {
          x.group = menus;
          menus.forEach(z => {
            map.set(z.name, z);
          });
        }
      });
      state.menuMap = map;
      state.menuList = list;
      return list;
    },
    CLEAR_ALL: (state) => {
      // state.menuList = [];
      state.tabList = [];
      state.currTab = {};
      state.currTabName = '';
      state.isLoad = false;
      // state.currMenu = {};
      // state.allPaths = [];
      // state.userMeta = {};
    },
    SET_TAB_LIST: (state: State, tabList) => {
      state.tabList = tabList;

      // 标签全部关闭，当前菜单指向第一个分组
      // if (tabList.length === 0) {
      //   state.currMenu = { header: state.menuList[0] };
      // }
    },
    SET_CURR_TAB: (state, tab) => {
      state.currTab = tab;
      state.currTabName = tab.name || '';

      // if (tab.header) {
      //   state.currMenu = { header: tab.header, side: tab.side };
      // }
    },
    SET_CURR_TAB_NAME(state, tabName) {
      state.currTabName = tabName;
    },
    ADD_TAB: (state: State, tab) => {
      state.tabList.push(tab);
      state.currTab = tab;
    },
    SET_IS_LOAD: (state, isLoad) => {
      state.isLoad = isLoad;
    },
  },
  actions: {
    setUserMeta: ({ commit }, userMeta) => {
      commit('SET_USER_META', userMeta);
    },
    setMenuList: ({ commit }, menuList) => {
      commit('SET_MENU_LIST', menuList);
    },
    setTabList: ({ commit }, tabList) => {
      commit('SET_TAB_LIST', tabList);
    },
    setCurrTab: ({ commit }, tab) => {
      commit('SET_CURR_TAB', tab);
    },
    addTab: ({ commit }, tab) => {
      commit('ADD_TAB', tab);
    },
    setCurrTabName: ({ commit }, tab) => {
      commit('SET_CURR_TAB_NAME', tab);
    },
    clearAll: ({ commit }) => {
      commit('CLEAR_ALL');
    },
  },
  getters: {
    menuList: state => state.menuList,
    menuMap: state => state.menuMap,
    tabList: state => state.tabList,
    currTab: state => state.currTab,
    currTabName: state => state.currTabName,
    userMeta: state => state.userMeta,
    isAgent: state => state.userMeta.type === constants?.enums.userType.agent || state.userMeta.type === constants?.enums.userType.guest
  },
  modules: {
  },
});
