
import { defineComponent, PropType, ref, reactive, toRefs } from 'vue';
import { RadioOptions } from '@/types/form.config';
import { SelectItem } from '@/types/base.config';

export default defineComponent({
  name: 'RadioWidget',
  props: {
    opts: {
      type: Object as PropType<RadioOptions>,
      default: () => ({})
    },
    modelValue: [String, Number, Boolean]
  },
  emits: ['update:modelValue', 'itemChange'],
  setup(props, { emit }) {
    const value = ref(props.modelValue as string);
    const radioOpts = reactive(props.opts);
    const options = reactive(radioOpts.options as SelectItem[]);
    const verticalStyle = { display: 'block', height: '30px', lineHeight: '30px' };

    const onChange = () => {
      const val = value.value;
      const retVal = radioOpts.evaluate?.({ value: val, text: options.find(x => val === x.value)!.label }) || val;
      emit('update:modelValue', retVal);
      emit('itemChange', radioOpts.field);
    };
    return { value, radioOpts, options, verticalStyle, onChange };
  }
});
