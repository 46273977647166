
import { defineComponent, PropType, ref, reactive, watch, computed } from 'vue';
import { cloneDeep, clone } from 'lodash';
import { useRoute } from 'vue-router';
import { useInject } from '@/utils/inject';
import { ColumnOptions, CommandOptions, SimpleTableOptions } from '@/types/table.config';
import { DataItem } from '@/types/app.global';
import { Converter } from '@/types/base.config';
import constants from '@/utils/constants';
import Clipboard from 'clipboard';

/**
 * 使用本地数据源的简单表格
 * 1、数据填充与移除
 * 2、格式化
 * 3、单元格渲染
 */
export default defineComponent({
  name: 'SimpleTable',
  props: {
    opts: {
      type: Object as PropType<SimpleTableOptions>,
      default: () => ({})
    }
  },
  setup(props) {
    // 1. 变量
    // 暴露变量
    const tableOpts = reactive(props.opts);
    const commands = ref<CommandOptions[]>(tableOpts.commands || []);
    const rowKey = tableOpts.rowKey || 'id';
    const dataList = ref([] as DataItem[]);
    const groupMap = new Map<string, ColumnOptions[]>();

    // 本地变量
    const { message, bus } = useInject();
    let selectKeys = [] as number[];
    const c = new Converter();

    // 2. 计算属性&监听
    const showList = computed(() => {
      let list = [] as DataItem[];
      if (dataList.value.length > 0) {
        list = cloneDeep(dataList.value);
        list.forEach(item => {
          Object.keys(item).forEach(k => {
            if (item[k] == null) {
              item[k] = '';
            }
            const val = c.convert(k, item);
            item[k] = val == null || val === 'null' ? '' : val;
          });
        });
      }
      return list;
    });

    // 数据源发生变化，重置选中项
    watch(dataList, () => {
      selectKeys = [];
      if (tableOpts.selection) {
        tableOpts.selection.selectedRowKeys = [];
      }
    });

    // 3. 方法
    // 设置数据
    const setData = (list: DataItem[]) => {
      dataList.value = list;
    };

    // 获取数据
    const getData = () => cloneDeep(dataList.value);

    // 替换本地数据
    const replace = (data: DataItem) => {
      const n = dataList.value.findIndex(x => x[rowKey] !== undefined && x[rowKey] === data[rowKey]);
      if (n > -1) {
        dataList.value[n] = data;
      }
    };

    // 移除本地数据
    const remove = (data: DataItem) => {
      setData(dataList.value.filter(x => x[rowKey] !== data[rowKey]));
    };

    const setSelectedKeys = (keys: number[]) => {
      selectKeys = keys;
    };

    const getSelectedKeys = () => selectKeys;

    const getSelectedData = () => {
      const rows = [] as DataItem[];

      selectKeys.forEach(k => {
        const row = dataList.value.find(x => x[rowKey] === k);
        if (row) {
          rows.push(row);
        }
      });
      return rows;
    };

    const onSelect = (data: any) => {
      tableOpts.builtinCmd?.select?.(data);
    };

    // 复制
    const doCopy = (isCopy: boolean, label: string, event: any) => {
      if (isCopy) {
        Clipboard.copy(event.target);
        message?.info(label + ' 已复制', 1);
      }
    };

    const onPageNav = (data: DataItem) => {
      const nav = tableOpts.builtinCmd?.nav;
      if (nav) {
        bus?.emit(constants.event.pageNav, { page: nav.page, params: nav.evaluate?.(data) });
      }
    };

    // 4. 初始化工作
    // 如果设置了单选或多选项，则指定绑定事件
    if (tableOpts.selection) {
      tableOpts.selection.columnWidth = 40;
      tableOpts.selection.onChange = (keys: number[]) => {
        selectKeys = keys;
        tableOpts.selection!.selectedRowKeys = keys;
      };

      tableOpts.selection.selectedRowKeys = [];
    }

    // 命令处理
    const initCommand = () => {
      if (tableOpts.builtinCmd?.select) {
        commands.value.push({ label: '选择', action: onSelect, disabled: () => false });
      }

      if (tableOpts.builtinCmd?.nav) {
        commands.value.push({ label: tableOpts.builtinCmd?.nav.label || '跳转', action: onPageNav, disabled: () => false });
      }

      if (tableOpts.builtinCmd?.custom) {
        commands.value.push(...tableOpts.builtinCmd?.custom);
      }

      const perms = useRoute().meta.perms as string[];
      commands.value = commands.value.filter(x => (x.perm ? perms.filter(y => y === x.perm).length > 0 : true));
    };

    // 列处理
    const initShowCols = () => {
      let tmpCols: ColumnOptions[] = [];
      const actualCols: ColumnOptions[] = [];

      // 处理分组
      let groupCols: ColumnOptions[];
      tableOpts.cols.forEach(col => {
        col.dataIndex = col.field;
        col.title = col.label;
        if (col.group) {
          groupCols = [];
          col.group.forEach(gc => {
            gc.dataIndex = gc.field;
            gc.title = gc.label;
            actualCols.push(gc);
            groupCols.push(gc);
          });

          groupMap.set(col.dataIndex!, groupCols);
          tmpCols.push(col);
        } else {
          actualCols.push(col);
          tmpCols.push(col);
        }
      });
      c.init(actualCols);

      // 添加index列
      if (tableOpts.index) {
        tmpCols = [{ dataIndex: 'col-index', title: '', width: 40, align: 'center' }, ...tmpCols];
      }

      // 添加command列
      if (commands.value.length > 0) {
        const count = commands.value.map(x => x.label.length).reduce((a, b) => a + b);
        tmpCols.push({ dataIndex: 'col-cmd', title: '操作', align: 'center', width: 60 + count * 15 });
      }
      return tmpCols;
    };

    initCommand();
    const showCols = initShowCols();
    const expose = { setData, getData, replace, remove, setSelectedKeys, getSelectedKeys, getSelectedData };
    return { tableOpts, rowKey, dataList, showList, showCols, commands, groupMap, constants, expose, doCopy, clone };
  }
});
