import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, _mergeProps({
      value: _ctx.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      style: {"width":"200px"},
      "default-active-first-option": false
    }, _ctx.bindProp, {
      loading: _ctx.loading,
      "filter-option": false,
      "not-found-content": null,
      options: _ctx.options,
      onSelect: _ctx.onSelect,
      onDropdownVisibleChange: _ctx.onVisibleChange
    }), null, 16, ["value", "loading", "options", "onSelect", "onDropdownVisibleChange"])
  ]))
}