
import { defineComponent, onMounted, PropType, reactive, ref } from 'vue';
import { useInject } from '@/utils/inject';
import { DataItem } from '@/types/app.global';
import { SearchTableOptions } from '@/types/table.config';
import { SimpleTableExposeType } from '@/types/types.config';

import SimpleTable from './SimpleTable.vue';
import CriteriaWidget from './CriteriaWidget.vue';
import PaginationWidget from './PaginationWidget.vue';

/**
 * 带查询功能的表格
 * 1、远程加载数据
 * 2、分页
 */
export default defineComponent({
  name: 'SearchTable',
  components: {
    // PaginationWidget,
    CriteriaWidget, SimpleTable },
  props: {
    opts: {
      type: Object as PropType<SearchTableOptions>,
      default: () => ({})
    }
  },
  setup(props) {
    // 对外暴露
    const tableOpts = reactive(props.opts);
    const table = ref<SimpleTableExposeType>();
    const total = ref(0);
    const page = ref(1);

    // 局部变量
    const { message } = useInject();
    const remote = tableOpts.search.remote;
    let criteriaEntity = {};
    // let page = 1;
    const size = 10;

    const resolveParams = () => {
      let params = criteriaEntity || {};
      if (tableOpts.pageable !== false) {
        params = { ...params, page: page.value, size };
      }

      // 添加配置信息中的查询参数
      if (remote.params) {
        params = { ...params, ...remote.params };
      }
      return params;
    };

    const onSearch = (criteria: DataItem) => {
      criteriaEntity = criteria;
      doSearch();
    };

    const doSearch = () => {
      const params = resolveParams();
      tableOpts.loading = true;

      remote.action(params).then(res => {
        tableOpts.loading = false;
        if (res.respType === 'OK') {
          const data = (res.respData.records || res.respData) as DataItem[];
          let list = !Array.isArray(data) ? [data] : data;
          list = remote.handle?.(list) || list;
          total.value = res.respData.totalElements || res.respData.total || list.length;
          if (list.length === 0) {
            message?.info('查询无数据');
            table.value?.expose.setData([]);
          } else {
            table.value?.expose.setData(list);
          }
        } else {
          message?.warn(res.respMesg);
        }
      }, () => { tableOpts.loading = false; });
    };

    const onPageChange = (p: number) => {
      // page = p;
      doSearch();
    };

    const onReset = () => {
      criteriaEntity = {};
      page.value = 1;
      total.value = 0;
      table.value?.expose.setData([]);
    };

    // 刷新数据，但不清除查询条件
    const refresh = () => {
      doSearch();
    };

    // 远程加载数据
    const loadData = (params: DataItem) => {
      onSearch(params);
    };

    const expose = { refresh, loadData } as DataItem;
    onMounted(() => {
      if (table.value?.expose) {
        for (const [k, v] of Object.entries(table.value.expose)) {
          expose[k] = v;
        }
      }

      // 抓取数据
      if (tableOpts.search?.fetch === true) {
        doSearch();
      }
    });
    return { table, tableOpts, total, page, size, onSearch, onReset, onPageChange, expose };
  }
});
