import { get, post, postForm, postFormData } from '@/https/req';
import { DataItem } from '@/types/app.global';

const BASE_URL = process.env.VUE_APP_BASE_URL;

export const login = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/sys/user/login`, data);
export const pageUser = (data: DataItem) => get(`${BASE_URL}/ymd/backend/sys/user/page`, data);
export const userRoles = (data: DataItem) => get(`${BASE_URL}/ymd/backend/sys/user/roles`, data);
export const createUser = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/sys/user/create`, data);
export const updateUser = (data: DataItem) => post(`${BASE_URL}/ymd/backend/sys/user/update`, data);
export const deleteUser = (data: DataItem) => post(`${BASE_URL}/ymd/backend/sys/user/delete`, data);
export const userReal = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/sys/user/real`, data);

export const pageRole = (data: DataItem) => get(`${BASE_URL}/ymd/backend/sys/role/page`, data);
export const listRole = (data: DataItem) => get(`${BASE_URL}/ymd/backend/sys/role/list`, data);
export const roleMenus = (data: DataItem) => get(`${BASE_URL}/ymd/backend/sys/role/menus`, data);
export const createRole = (data: DataItem) => post(`${BASE_URL}/ymd/backend/sys/role/create`, data);
export const updateRole = (data: DataItem) => post(`${BASE_URL}/ymd/backend/sys/role/update`, data);
export const deleteRole = (data: DataItem) => post(`${BASE_URL}/ymd/backend/sys/role/delete`, data);
export const roleAuth = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/sys/role/auth`, data);

export const loadMenu = (data: DataItem = {}) => get(`${BASE_URL}/ymd/backend/sys/menu/load`, data);
export const pageMenu = (data: DataItem) => get(`${BASE_URL}/ymd/backend/sys/menu/page`, data);
export const updateMenu = (data: DataItem) => post(`${BASE_URL}/ymd/backend/sys/menu/update`, data);

export const upload = (data: DataItem) => postFormData(`${BASE_URL}/ymd/backend/sys/test/upload`, data);
export const listDept = (data: DataItem) => get(`${BASE_URL}/ymd/backend/sys/dept/list`, data);
