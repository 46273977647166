import store from '@/store';

export function clearLoginInfo(): void {
  delCookie('ymd_token');
  delCookie('ymd_alias');
  store.commit('CLEAR_ALL');
  sessionStorage.clear();
}

export function getCookie(name: string): string {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  const arr = document.cookie.match(reg);
  return arr ? arr[2] : '';
}

export function setCookie(name: string, value: string, expDays = 30): void{
  const expDate = new Date();
  expDate.setDate(expDate.getDate() + expDays);
  document.cookie = name + '=' + escape(value) + ((expDays == null) ? '' : ';expires=' + expDate.toUTCString());
}

export function delCookie(name: string): void {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name);
  if (cval != null) {
    document.cookie = name + '=' + cval + ';expires=' + exp.toUTCString();
  }
}
