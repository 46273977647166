import { useStore } from 'vuex';

export function useGetters() {
  const store = useStore();
  const userMeta = store.getters.userMeta;
  const isAgent = store.getters.isAgent;
  return { userMeta, isAgent };
}

export function useMutations() {
  const store = useStore();
  const setTabList = (list: any[]) => {
    store.commit('SET_TAB_LIST', list);
  };

  const setCurrTab = (tab: any) => {
    store.commit('SET_CURR_TAB', tab);
  };

  const setUserMeta = (userMeta: any) => {
    store.commit('SET_USER_META', userMeta);
  };

  return { setTabList, setCurrTab, setUserMeta };
}
