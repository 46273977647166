import { get, post, postForm } from '@/https/req';
import { DataItem } from '@/types/app.global';

const BASE_URL = process.env.VUE_APP_BASE_URL;

export const listActivityCompose = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/activitycompose/list`, data);

// 激活
export const listActivatePolicy = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/activatepolicy/list`, data);
export const editActivatePolicy = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/market/activatepolicy/edit`, data);
export const getActivateCheck = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/activatecheck/info`, data);
export const getActivateReward = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/activatereward/info`, data);

// 返现
export const listRewardPolicy = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/rewardpolicy/list`, data);
export const editRewardPolicy = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/market/rewardpolicy/edit`, data);

// 结算价
export const listProfitPolicy = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/profit/policy/list`, data);
export const editProfitPolicy = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/market/profit/policy/edit`, data);
export const listAgentProfit = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/profit/agent/list`, data);
export const listProfitModify = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/profit/modify/list`, data);
export const updateAgentProfit = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/market/profit/modify/edit`, data);

// 分润关闭
export const pageProfitClose = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/profit/close/page`, data);
export const getCurrentTurn = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/profit/close/currentTurn`, data);
export const editProfitClose = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/market/profit/close/edit`, data);

// 分润关闭权限
export const getCloseAuth = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/profit/closeAuth/get`, data);
export const editCloseAuth = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/market/profit/closeAuth/edit`, data);

// 额外活动
export const listExtraPolicy = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/extra/policy/list`, data);
export const editExtraPolicy = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/market/extra/policy/edit`, data);
export const getExtraCheck = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/extra/check/page`, data);
export const getExtraLog = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/extra/log/page`, data);

// 流量卡
export const listSimCardType = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/simcard/type/list`, data);
export const createSimCardType = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/market/simcard/type/create`, data);
export const updateSimCardType = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/market/simcard/type/update`, data);
export const deleteSimCardType = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/market/simcard/type/delete`, data);

export const listSimCardProfit = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/simcard/profit/list`, data);
export const editSimCardProfit = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/market/simcard/profit/edit`, data);
export const pageSimCardModify = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/simcard/modify/page`, data);
export const infoSimCardModify = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/simcard/modify/info`, data);
export const pageSimCardFlow = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/simcard/flow/page`, data);

export const pageSimCardOrder = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/simcard/order/page`, data);
export const pageSimCardReward = (data: DataItem) => get(`${BASE_URL}/ymd/backend/market/simcard/reward/page`, data);
