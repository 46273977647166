
import { defineComponent, PropType, provide, reactive, ref } from 'vue';
import { useInject } from '@/utils/inject';
import { CriteriaOptions } from '@/types/table.config';
import { DataItem } from '@/types/app.global';
import constants from '@/utils/constants';

import { ReloadOutlined, SearchOutlined } from '@ant-design/icons-vue';
import SelectWidget from '@/components/form/SelectWidget.vue';
import CascadeWidget from '@/components/form/CascadeWidget.vue';
import DateWidget from '@/components/form/DateWidget.vue';

export default defineComponent({
  name: 'CriteriaWidget',
  components: { DateWidget, SelectWidget, CascadeWidget, SearchOutlined, ReloadOutlined },
  props: {
    opts: {
      type: Object as PropType<CriteriaOptions>,
      default: () => ({})
    }
  },
  emits: ['search', 'reset'],
  setup(props, context) {
    const criteriaOpts = reactive(props.opts);
    const entity = ref({} as DataItem);
    let validEntity = {} as DataItem;
    const rules = {} as DataItem;
    const choiceText = ref('');

    // 本地变量
    const { bus, message } = useInject();
    const resetEvent = Symbol('reset-event');
    provide(constants.event.reset, resetEvent);

    const onSearch = () => {
      // 根据entity组装data
      let params = {} as DataItem;
      const fields = new Set<string>();
      for (const [k, v] of Object.entries(entity.value)) {
        if (v !== undefined) {
          if (v !== null && typeof v === 'object' && !Array.isArray(v)) {
            params = { ...params, ...v };
            Object.keys(v).forEach(x => fields.add(x));
          } else if (!fields.has(k)) {
            params[k] = v;
          }
        }
      }

      // 必填检查
      let msg = '';
      for (const i in criteriaOpts.cols) {
        const col = criteriaOpts.cols[i];
        if (col.required && !entity.value[col.field]) {
          msg = `查询条件不满足，【${col.label}】不能为空`;
          break;
        }
      }

      // 多选一检查
      if (!msg && choiceText.value !== '') {
        let n = 0;
        for (const i in criteriaOpts.cols) {
          const col = criteriaOpts.cols[i];
          if (col.choice && entity.value[col.field]) {
            n += 1;
          }
        }
        if (n === 0) {
          msg = `查询条件不满足，${choiceText.value}至少填写一项`;
        }
      }

      if (msg) {
        message?.warning(msg);
      } else {
        params = criteriaOpts.evaluate?.(params) || params;
        context.emit('search', params);
      }
    };

    const initEntity = () => {
      // 使用外部传入的entity
      if (criteriaOpts.entity) {
        entity.value = criteriaOpts.entity;
      }
      // 根据cols属性初始化entity
      else {
        const entityTmp = {} as DataItem;
        criteriaOpts.cols?.forEach(col => {
          if (typeof col.defVal === 'function') {
            col.defVal = col.defVal();
          }
          entityTmp[col.field] = col.defVal || undefined;
        });
        entity.value = entityTmp;
      }
    };

    const onReset = () => {
      initEntity();
      validEntity = entity.value;
      context.emit('reset');
      bus?.emit(resetEvent);
    };

    criteriaOpts.cols.forEach(col => {
      if (col.choice) {
        choiceText.value += '【' + col.label + '】';
      }

      if (typeof col.visible === 'function') {
        col.visible = col.visible();
      }
    });

    initEntity();
    return { criteriaOpts, entity, validEntity, rules, onSearch, onReset };
  }
});
