import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { clearLoginInfo, getCookie } from '@/utils/storage';
import { loadMenu } from '@/https/modules/sys';
import { Menu } from '@/types/app.global';
import constants from '@/utils/constants';
import store from '@/store/index';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';

const routes: Array<RouteRecordRaw> = [
  // { path: '/notfound', component: NotFound, name: 'notfound', meta: { title: '404未找到' } },
  { path: '/login', component: Login, name: 'login', meta: { title: '登录' } },
  { path: '/', component: Home, name: 'home',
    beforeEnter(to, from, next) {
      // console.log('beforeEnter', from.path, to.path)
      const token = getCookie('ymd_token');
      if (!token) {
        clearLoginInfo();
        next({ name: 'login' });
      } else {
        next();
      }
    }
  },
  // {path: '/:catchAll(.*)', component: NotFound},
  // {path: '/:pathMatch(.*)', redirect: '/'},
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoaded = store.state.isLoad;
  if (!isLoaded && to.path !== '/login') {
    // console.log('beforeEach', from.path, to.path);
    loadMenus();
  }
  next();
});

function loadMenus() {
  const token = getCookie('ymd_token');
  if (token) {
    const userMeta = store.state.userMeta;

    loadMenu({ uid: userMeta.uid, roleId: userMeta.roleId }).then(res => {
      if (res.respType === 'OK') {
        store.commit('SET_IS_LOAD', true);
        const menuList = res.respData;
        store.dispatch('setMenuList', menuList).then(() => {
          const perms = res.respData.filter((x: any) => x.type === '3');
          for (const menu of store.state.menuMap.values()) {
            resolveRoute(menu, perms);
          }
        });
      } else {
        router.replace({ name: 'login' });
      }
    });
  }
}

function resolveRoute(menu: Menu, perms: Menu[]) {
  if (menu.type === constants.enums.menuType.page) {
    const path = menu.path;
    const route = {
      path: '/' + menu.url,
      name: menu.name,
      meta: {
        label: menu.label,
        perms: perms.filter(x => x.pid === menu.id).map(x => x.name) || []
      },
      component: () => import(`@/views/${path}`),
    };

    router.addRoute('home', route); // 动态路由加载到home页面，由MainContent显示
  }
}

export default router;
