import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upload_outlined = _resolveComponent("upload-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_loading_outlined = _resolveComponent("loading-outlined")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.opts.type === 'upload')
      ? (_openBlock(), _createBlock(_component_a_upload, {
          key: 0,
          "file-list": _ctx.fileList,
          "onUpdate:file-list": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fileList) = $event)),
          multiple: _ctx.opts.multiple,
          accept: _ctx.opts.accept || '*/*',
          "before-upload": _ctx.beforeUpload,
          onChange: _ctx.handleChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, null, {
              default: _withCtx(() => [
                _createVNode(_component_upload_outlined),
                _createTextVNode("上传 ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["file-list", "multiple", "accept", "before-upload", "onChange"]))
      : (_ctx.opts.type === 'upload-pic-card')
        ? (_openBlock(), _createBlock(_component_a_upload, {
            key: 1,
            "file-list": _ctx.fileList,
            "onUpdate:file-list": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fileList) = $event)),
            multiple: _ctx.opts.multiple,
            accept: _ctx.opts.accept || 'image/*',
            "before-upload": _ctx.beforeUpload,
            "list-type": "picture-card",
            onChange: _ctx.handleChange
          }, {
            default: _withCtx(() => [
              (_ctx.opts.multiple || _ctx.fileList.length < 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (_ctx.loading)
                      ? (_openBlock(), _createBlock(_component_loading_outlined, { key: 0 }))
                      : (_openBlock(), _createBlock(_component_plus_outlined, { key: 1 }))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["file-list", "multiple", "accept", "before-upload", "onChange"]))
        : _createCommentVNode("", true)
  ]))
}