import { inject } from 'vue';
import { BusType, HttpsType, MapperType, MessageType, NotifyType, UtilsType, ConstantsType } from '@/types/types.config';

export function useInject() {
  const https = inject<HttpsType>('https');
  const mapper = inject<MapperType>('mapper');
  const bus = inject<BusType>('bus');
  const utils = inject<UtilsType>('utils');
  const notify = inject<NotifyType>('notify');
  const message = inject<MessageType>('message');
  const constants = inject<ConstantsType>('constants');
  return { https, mapper, bus, utils, notify, message, constants };
}
