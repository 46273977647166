import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_checkbox_group, {
      value: _ctx.checkList,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkList) = $event)),
      options: _ctx.options,
      onChange: _ctx.onChange
    }, null, 8, ["value", "options", "onChange"])
  ]))
}