import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_close_circle_filled = _resolveComponent("close-circle-filled")!
  const _component_retrieve_modal = _resolveComponent("retrieve-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.retrieveOpts.disabled === true)
      ? (_openBlock(), _createBlock(_component_a_input, {
          key: 0,
          value: _ctx.value,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          style: {width: '200px'},
          disabled: ""
        }, null, 8, ["value"]))
      : (_openBlock(), _createBlock(_component_a_input, {
          key: 1,
          value: _ctx.value,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
          style: {width: '200px', cursor: 'pointer'},
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.retrieveOpts.visible = true)),
          readonly: ""
        }, _createSlots({ _: 2 }, [
          (_ctx.retrieveOpts.showClose !== false)
            ? {
                name: "suffix",
                fn: _withCtx(() => [
                  (_ctx.value !== '')
                    ? (_openBlock(), _createBlock(_component_close_circle_filled, {
                        key: 0,
                        style: {"cursor":"pointer","color":"rgba(0, 0, 0, 0.45)"},
                        onClick: _ctx.onClear
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value"])),
    _createVNode(_component_retrieve_modal, {
      opts: _ctx.retrieveOpts,
      onSelect: _ctx.onSelect
    }, null, 8, ["opts", "onSelect"])
  ]))
}