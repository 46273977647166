import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_radio = _resolveComponent("a-radio")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.opts.style)
      ? (_openBlock(), _createBlock(_component_a_radio_group, {
          key: 0,
          value: _ctx.value,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          "button-style": _ctx.opts.style || 'outline',
          disabled: _ctx.opts.disabled,
          onChange: _ctx.onChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
              return (_openBlock(), _createBlock(_component_a_radio_button, {
                key: item.value,
                value: item.value,
                style: _normalizeStyle(_ctx.opts.vertical ? _ctx.verticalStyle : {})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["value", "style"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value", "button-style", "disabled", "onChange"]))
      : (_openBlock(), _createBlock(_component_a_radio_group, {
          key: 1,
          value: _ctx.value,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
          disabled: _ctx.opts.disabled,
          onChange: _ctx.onChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
              return (_openBlock(), _createBlock(_component_a_radio, {
                key: item.value,
                value: item.value,
                style: _normalizeStyle(_ctx.opts.vertical ? _ctx.verticalStyle : {})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["value", "style"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value", "disabled", "onChange"]))
  ]))
}