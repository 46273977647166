
import { defineComponent, PropType, ref, watch } from 'vue';
import { useInject } from '@/utils/inject';
import { RetrieveItemOptions } from '@/types/form.config';
import RetrieveInput from '@/components/retrieve/RetrieveInput.vue';

export default defineComponent({
  name: 'UserRetrieve',
  components: { RetrieveInput },
  props: {
    type: String,
    opts: {
      type: Object as PropType<RetrieveItemOptions>,
      default: () => ({})
    },
    modelValue: [String],
  },
  setup(props, { emit }) {
    const { https, mapper } = useInject();
    const value = ref(props.modelValue);

    watch(value, () => {
      emit('update:modelValue', value.value);
    });

    const retrieveOpts = {
      ...props.opts,
      mapper: ['uid', 'alias'],
      title: '代理检索',
      visible: false,
      content: {
        search: {
          criteria: {
            cols: [
              {
                field: 'uid',
                label: '代理ID',
                choice: true
              },
              {
                field: 'phone',
                label: '手机号',
                choice: true
              },
              {
                field: 'alias',
                label: '用户名',
                choice: true
              }
            ]
          },
          remote: {
            params: props.opts.params || {},
            // params: { type: props.type },
            action: https?.member.listUser
          }
        },
        cols: [
          {
            field: 'uid',
            label: '用户ID'
          },
          {
            field: 'alias',
            label: '用户名'
          },
          {
            field: 'phone',
            label: '手机号'
          },
          {
            field: 'name',
            label: '姓名'
          },
          {
            field: 'type',
            label: '类型',
            options: mapper?.member.userType
          },
          {
            field: 'real',
            label: '实名状态',
            options: mapper?.member.realStatus
          },
          {
            field: 'ctime',
            label: '注册时间',
          }
        ],
      }
    };

    return { value, retrieveOpts };
  }
});
