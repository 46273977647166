
import { defineComponent, reactive, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useInject } from '@/utils/inject';
import { useMutations } from '@/store/mixin';
import constants from '@/utils/constants';

export default defineComponent({
  name: 'Login',
  setup() {
    const router = useRouter();
    const { https, utils, message } = useInject();
    const { setUserMeta } = useMutations();
    const profile = process.env.VUE_APP_PROFILE;

    const loading = ref(false);
    const lostLoading = ref(false);
    const smsCodeVisible = ref(false);
    const user = reactive({ alias: '', pwd: '', smsCode: '' });
    const extra = profile === constants.profile.prd ? '' : '（' + profile + '）';
    const userRef = ref();
    const counter = ref(0); // 验证码发送倒计时

    const lostOpts = reactive({
      title: '找回密码',
      visible: false,
      alias: '',
      pwd: '',
      sms: ''
    });

    if (profile === 'dev' || profile === 'sit') {
      user.alias = 'admin';
      user.pwd = '123456';
    }

    const countdown = () => {
      counter.value = 60;
      const t = setInterval(() => {
        counter.value -= 1;
        if (counter.value === 0) {
          clearInterval(t);
        }
      }, 1000);
    };

    const localLogin = (phone: string, token?: string) => {
      https?.sys.login({ ...user, phone }).then(res => {
        loading.value = false;
        if (res.respType === 'OK') {
          const userMeta = res.respData;
          const actualToken = token || userMeta.token;
          userMeta.token = actualToken;
          setUserMeta(userMeta);
          utils?.storage.setCookie('ymd_token', actualToken);
          utils?.storage.setCookie('ymd_alias', userMeta.alias);
          router.replace('/'); // 跳转主页
        } else {
          message?.warning(res.respMesg);
        }
      }, () => { loading.value = false; });
    };

    const doLogin = () => {
      loading.value = true;

      https?.member.login(user).then(res => {
        if (res.respType !== 'OK') {
          message?.warning(res.respMesg);
          loading.value = false;
          return;
        }

        // 需要短信验证，显示短信验证码输入框
        if (res.respCode === '102.103.002') {
          loading.value = false;
          smsCodeVisible.value = true;
          message?.warning(res.respMesg);
          return;
        }

        // manager后台登录，获取角色、门店等信息
        localLogin(res.respData.phone, res.respData.token);
      }, () => { loading.value = false; });
    };

    // 发送登录验证码
    const sendSmsCode = () => {
      if (!user.alias) {
        message?.warning('请输入用户名');
        return;
      }

      user.smsCode = '';
      https?.member.sendLoginSmsCode({ alias: user.alias }).then(res => {
        if (res.respType === 'OK') {
          message?.info('短信发送成功');
        } else {
          message?.warning(res.respMesg);
        }
      });
    };

    const showLost = () => {
      lostOpts.alias = '';
      lostOpts.pwd = '';
      lostOpts.sms = '';
      lostOpts.visible = true;
    };

    // 发送忘记密码验证码
    const sendLostSmsCode = () => {
      if (!lostOpts.alias) {
        message?.warning('请输入用户名');
        return;
      }

      lostOpts.sms = '';
      https?.member.sendLostSmsCode({ alias: lostOpts.alias }).then(res => {
        if (res.respType === 'OK') {
          countdown();
          message?.info('短信发送成功');
        } else {
          message?.warning(res.respMesg);
        }
      });
    };

    const doLostPwd = () => {
      if (!lostOpts.alias) {
        message?.warning('请输入用户名');
        return;
      }

      if (!lostOpts.sms) {
        message?.warning('请输入短信验证码');
        return;
      }

      if (!lostOpts.pwd) {
        message?.warning('请输入新密码');
        return;
      }

      lostLoading.value = true;
      https?.member.lostLoginPwd({ alias: lostOpts.alias, sms: lostOpts.sms, pwd: lostOpts.pwd }).then(res => {
        lostLoading.value = false;
        if (res.respType === 'OK') {
          message?.success('修改成功');
          lostOpts.visible = false;
          lostOpts.alias = '';
          lostOpts.pwd = '';
          lostOpts.sms = '';
        } else {
          message?.warning(res.respMesg);
        }
      }, () => { lostLoading.value = false; });
    };

    onMounted(() => {
      userRef.value.focus();
    });

    return { loading, lostLoading, smsCodeVisible, user, lostOpts, extra, userRef, counter, doLogin, sendSmsCode, sendLostSmsCode, doLostPwd, showLost };
  }
});
