export interface UserMeta{
  uid: number
  phone: string
  name: string
  alias: string
  type: string
  merId: number
  perId: number
  divide: number
  regTime: string
  token: string
  deptId: number
  deptName: string
  roleId: number
  roleName: string
}

export class State {
  menuList: Menu[] = [];
  menuMap: Map<string, Menu> = new Map<string, Menu>();
  tabList: Menu[] = [];
  currTab: any = {};
  currTabName = '';
  allPaths: string[] = [];
  userMeta = {} as UserMeta;
  isLoad = false; // 是否加载过菜单
}

export interface Resp{
  respType: string
  respCode: string
  respMesg: string
  respDesc: string
  respData: any
}

export interface DataItem{
  [prop: string]: any
}

export class Menu {
  id = 0;
  name = '';
  label = '';
  path = '';
  level = 0;
  pid = 0;
  leaf = '';
  url = '';
  type = '';
  group: Menu[] = [];
}

export class Tab {
  label = '';
  name= '';
}
