
import { defineComponent, PropType, reactive, ref } from 'vue';
import { RetrieveOptions } from '@/types/table.config';
import { SearchTableExposeType } from '@/types/types.config';
import { useInject } from '@/utils/inject';
import { ModalOptions } from '@/types/container.config';
import { DataItem } from '@/types/app.global';

import ModalWidget from '@/components/widget/ModalWidget.vue';
import SearchTable from '@/components/table/SearchTable.vue';
import { CheckOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  name: 'RetrieveModal',
  components: { SearchTable, ModalWidget, CheckOutlined },
  props: {
    opts: {
      type: Object as PropType<ModalOptions<RetrieveOptions>>,
      default: () => ({})
    },
    modelValue: [Array]
  },
  emits: ['select'],
  setup(props, { emit }) {
    const tableRef = ref<SearchTableExposeType>();
    const modalOpts = reactive(props.opts);
    const { notify } = useInject();

    const selectMulti = () => {
      const rows = tableRef.value?.expose.getSelectedData() || [];
      if (rows.length === 0) {
        notify?.warning('请选择数据');
        return;
      }

      emit('select', rows);
      modalOpts.visible = false;
    };

    const selectSingle = (data: DataItem) => {
      emit('select', data);
      modalOpts.visible = false;
    };

    modalOpts.footer = false;
    modalOpts.width = '60%';

    // 单选模式
    const tableOpts = modalOpts.content;
    if (tableOpts.type !== 'multi') {
      tableOpts.builtinCmd = { select: selectSingle };
    } else {
      tableOpts.selection = { type: 'checkbox' };
    }

    return { tableRef, modalOpts, selectMulti };
  }
});
