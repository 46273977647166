import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createVNode as _createVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c85a2bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "criteria-wrap" }
const _hoisted_2 = {
  key: 0,
  style: {"color":"red"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_widget = _resolveComponent("select-widget")!
  const _component_cascade_widget = _resolveComponent("cascade-widget")!
  const _component_date_widget = _resolveComponent("date-widget")!
  const _component_user_retrieve = _resolveComponent("user-retrieve")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_reload_outlined = _resolveComponent("reload-outlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      layout: "inline",
      model: _ctx.validEntity
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.criteriaOpts.cols, (col, index) => {
          return _withDirectives((_openBlock(), _createBlock(_component_a_form_item, {
            key: index,
            required: col.required,
            style: {"margin-top":"5px"}
          }, {
            label: _withCtx(() => [
              (col.choice)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "? "))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(col.label), 1)
            ]),
            default: _withCtx(() => [
              (col.type === 'select')
                ? (_openBlock(), _createBlock(_component_select_widget, {
                    key: 0,
                    opts: col,
                    modelValue: _ctx.entity[col.field],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                  }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                : (col.type === 'cascade')
                  ? (_openBlock(), _createBlock(_component_cascade_widget, {
                      key: 1,
                      opts: col,
                      modelValue: _ctx.entity[col.field],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                    }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                  : (col.type === 'date' || col.type === 'month' || col.type === 'year' )
                    ? (_openBlock(), _createBlock(_component_date_widget, {
                        key: 2,
                        opts: col,
                        modelValue: _ctx.entity[col.field],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                      }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                    : (col.type === 'agent')
                      ? (_openBlock(), _createBlock(_component_user_retrieve, {
                          key: 3,
                          opts: col,
                          modelValue: _ctx.entity[col.field],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.entity[col.field]) = $event)
                        }, null, 8, ["opts", "modelValue", "onUpdate:modelValue"]))
                      : (_openBlock(), _createBlock(_component_a_input, {
                          key: 4,
                          value: _ctx.entity[col.field],
                          "onUpdate:value": ($event: any) => ((_ctx.entity[col.field]) = $event)
                        }, null, 8, ["value", "onUpdate:value"]))
            ]),
            _: 2
          }, 1032, ["required"])), [
            [_vShow, col.visible !== false]
          ])
        }), 128)),
        _createVNode(_component_a_space, { style: {"margin-top":"5px"} }, {
          default: _withCtx(() => [
            (_ctx.criteriaOpts.showSearch !== false)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  type: "primary",
                  onClick: _ctx.onSearch
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_search_outlined)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode("查询 ")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.criteriaOpts.cols?.length > 1 || _ctx.criteriaOpts.showReset === true)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  onClick: _ctx.onReset
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_reload_outlined)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode("重置 ")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "button", {}, undefined, true)
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["model"])
  ]))
}