import Mitt from 'mitt';
import * as storage from '@/utils/storage';
import constants from '@/utils/constants';
import mapper from '@/utils/mapper';
import { https } from '@/https';
import { notify, message } from '@/utils/notification';

const utils = { storage };
const mitt = Mitt();

export { utils, mapper, mitt };

export default {
  install: (app: any) => {
    app.provide('https', https);
    app.provide('utils', utils);
    app.provide('constants', constants);
    app.provide('message', message);
    app.provide('mapper', mapper);
    app.provide('bus', mitt);
    app.provide('notify', notify);
  }
};
