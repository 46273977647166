import { get } from '@/https/req';
import { DataItem } from '@/types/app.global';

const BASE_URL = process.env.VUE_APP_BASE_URL;

export const pageWithdraw = (data: DataItem) => get(`${BASE_URL}/ymd/backend/payment/withdraw/record/page`, data);
export const pageProfitReward = (data: DataItem) => get(`${BASE_URL}/ymd/backend/payment/profit/record/page`, data);
export const pageActReward = (data: DataItem) => get(`${BASE_URL}/ymd/backend/payment/reward/act/page`, data);
export const pageExtraReward = (data: DataItem) => get(`${BASE_URL}/ymd/backend/payment/reward/extra/page`, data);
export const pagePurchaseBonus = (data: DataItem) => get(`${BASE_URL}/ymd/backend/payment/purchasebonus/page`, data);
