import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cb0c764"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-region" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "table-wrap" }
const _hoisted_4 = { class: "pagination-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_criteria_widget = _resolveComponent("criteria-widget")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_simple_table = _resolveComponent("simple-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tableOpts.search?.criteria)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.tableOpts.search?.criteria)
            ? (_openBlock(), _createBlock(_component_criteria_widget, {
                key: 0,
                opts: _ctx.tableOpts.search.criteria,
                onSearch: _ctx.onSearch,
                onReset: _ctx.onReset
              }, {
                button: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "button", {}, undefined, true)
                ]),
                _: 3
              }, 8, ["opts", "onSearch", "onReset"]))
            : (_openBlock(), _createBlock(_component_a_space, { key: 1 }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "button", {}, undefined, true)
                ]),
                _: 3
              }))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_simple_table, {
        ref: "table",
        opts: _ctx.tableOpts
      }, null, 8, ["opts"]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_pagination, {
          size: "small",
          style: {"margin-top":"5px"},
          current: _ctx.page,
          "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
          "page-size": _ctx.size,
          "onUpdate:page-size": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.size) = $event)),
          total: _ctx.total,
          onChange: _ctx.onPageChange
        }, null, 8, ["current", "page-size", "total", "onChange"])
      ], 512), [
        [_vShow, _ctx.tableOpts.pageable !== false && _ctx.total !== 0]
      ])
    ])
  ]))
}