
import { defineComponent, PropType, provide, reactive } from 'vue';
import { useInject } from '@/utils/inject';
import { ModalOptions } from '@/types/container.config';
import { DataItem } from '@/types/app.global';
import constants from '@/utils/constants';

export default defineComponent({
  name: 'ModalWidget',
  props: {
    opts: {
      type: Object as PropType<ModalOptions>,
      default: () => ({})
    }
  },
  setup(props) {
    // 对外暴露
    const modalOpts = reactive(props.opts);
    const bindProp = {} as DataItem;

    // 本地变量
    const confirmEvent = Symbol('modal-confirm');
    const { bus, notify } = useInject();
    const notifyTitle = '【' + modalOpts.title + '】' || '操作';

    provide(constants.event.modalConfirm, confirmEvent);

    const doConfirm = (data: DataItem) => {
      modalOpts.loading = true;

      const action = modalOpts.action(data);
      if (action !== undefined) {
        action.then(res => {
          modalOpts.loading = false;
          if (res.respType === 'OK') {
            modalOpts.visible = false;
            notify?.success(res.respMesg, notifyTitle + '成功');
            modalOpts.onSuccess?.(data);
          } else {
            notify?.error(res.respMesg, notifyTitle + '失败');
          }
        }, () => { modalOpts.visible = false; });
      } else {
        modalOpts.loading = false;
      }
    };

    const onConfirm = () => {
      modalOpts.content ? bus?.emit(confirmEvent, doConfirm) : doConfirm({});
    };

    const initBindProp = () => {
      if (modalOpts.title) {
        bindProp.title = modalOpts.title;
      }

      bindProp.width = modalOpts.width || '520px';
      bindProp.height = modalOpts.height || '400px';
      bindProp.footer = modalOpts.footer;
      bindProp.closable = modalOpts.closable !== false;
    };
    initBindProp();
    return { modalOpts, bindProp, onConfirm };
  }
});
