import { DataItem, Resp } from './app.global';

export interface SelectItem {
  value: string | number | boolean
  label: string
}

// 远程请求配置
export interface RemoteOptions{
  // 请求参数，对象类型或箭头函数
  params?: DataItem
  // 执行远程请求的方法
  action(data: DataItem): Promise<Resp>
  // 发起远程请求前，对查询参数进行检查
  valid?(params: DataItem): string | undefined
  // 对远程加载的数据进行处理，由于data是原始数据，一般不直接修改，而是过滤等操作
  handle?(data: unknown): any
  // 发起远程请求前，对参数进行处理，比如添加额外请求参数
  prepare?(params: DataItem): DataItem
}

// 提示配置
export interface TipsOptions{
  // 提示文本
  msg: string,
  // 详细信息（一般不需要）
  desc?: string,
  // 图标类型，默认info
  type?: 'info'|'warning'|'danger'
}

// 远程处理接口
export interface RemoteHandler{
  remote?: RemoteOptions
}

// 对请求结果进行解析
export interface ResultResolver {
  // 从控件得到结果前最后一次处理，返回新的结果（data一般不是原始数据）
  evaluate?(data: DataItem): DataItem
}

export interface Translator {
  // 对指定字段进行格式化
  format?: (value: any, data?: any) => any
  // 类似下拉框的选项列表，根据value的值来显示text内容
  options?: SelectItem[]
}

export class Converter {
  map = new Map<string, Translator>();

  init(list: Translator[]): void{
    list.forEach(col => {
      if (col.format || col.options) {
        this.map.set((col as any).field, { format: col.format, options: col.options });
      }
    });
  }

  get(key: string): Translator | undefined {
    return this.map.get(key);
  }

  convert(k: string, item: DataItem): unknown {
    let val = item[k];
    const convert = this.get(k);
    if (convert) {
      const { options, format } = convert;

      // 将布尔和数字类型的value转成string
      if (typeof val === 'boolean') {
        val = val ? '1' : '0';
      } else if (typeof val === 'number') {
        val = val.toString();
      }

      // option的value可能是number类型，转成string
      val = options?.find(option => option.value.toString() === val)?.label || val;
      val = format?.(val, item) || val;
    }
    return val;
  }
}
