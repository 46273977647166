import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_outlined = _resolveComponent("check-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_search_table = _resolveComponent("search-table")!
  const _component_modal_widget = _resolveComponent("modal-widget")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_modal_widget, { opts: _ctx.modalOpts }, {
      content: _withCtx(() => [
        _createVNode(_component_search_table, {
          ref: "tableRef",
          opts: _ctx.modalOpts.content
        }, {
          button: _withCtx(() => [
            (_ctx.modalOpts.content.type === 'multi')
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  onClick: _ctx.selectMulti
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_check_outlined)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode("选择 ")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["opts"])
      ]),
      _: 1
    }, 8, ["opts"])
  ]))
}