import { get, postForm } from '@/https/req';
import { DataItem } from '@/types/app.global';

const BASE_URL = process.env.VUE_APP_BASE_URL;
const SERVER_URL = process.env.VUE_APP_SERVER_URL;

export const listUser = (data: DataItem) => get(`${BASE_URL}/ymd/backend/member/userinfo/list`, data);
export const regAgent = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/member/agent/reg`, data);
export const livableAgent = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/member/agent/livable`, data);

export const login = (params: DataItem) => postForm(`${SERVER_URL}/v500/ymd/user/auth/login/alias/web`, params); // web端登录
export const sendRegSmsCode = (params: DataItem) => postForm(`${SERVER_URL}/v500/ymd/user/hand/apply/sms/web`, params); // 发送注册验证码
export const sendLoginSmsCode = (params: DataItem) => postForm(`${SERVER_URL}/v500/ymd/user/auth/login/dual/sms/web`, params); // 发送登录验证码
export const sendLostSmsCode = (params: DataItem) => postForm(`${SERVER_URL}/v500/ymd/user/auth/login/lost/sms/web`, params); // 发送忘记密码验证码
export const swapLoginPwd = (params: DataItem) => postForm(`${SERVER_URL}/v500/ymd/user/auth/login/lost/swap/web`, params); // 更换密码（旧换新）
export const resetLoginPwd = (params: DataItem) => postForm(`${SERVER_URL}/v500/ymd/user/auth/login/lost/init/web`, params); // 重置密码
export const lostLoginPwd = (params: DataItem) => postForm(`${SERVER_URL}/v500/ymd/user/auth/login/lost/set/web`, params); // 找回密码（手机验证码）
