import { get, postForm, postFormData } from '@/https/req';
import { DataItem } from '@/types/app.global';

const BASE_URL = process.env.VUE_APP_BASE_URL;

// 配置项
export const pageProps = (data: DataItem) => get(`${BASE_URL}/ymd/backend/facade/config/props/page`, data);
export const createProps = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/facade/config/props/create`, data);
export const updateProps = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/facade/config/props/update`, data);
export const deleteProps = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/facade/config/props/delete`, data);

// 二维码
export const pageQrcode = (data: DataItem) => get(`${BASE_URL}/ymd/backend/facade/config/qrcode/page`, data);
export const createQrcode = (data: DataItem) => postFormData(`${BASE_URL}/ymd/backend/facade/config/qrcode/create`, data);
export const updateQrcode = (data: DataItem) => postFormData(`${BASE_URL}/ymd/backend/facade/config/qrcode/update`, data);
export const deleteQrcode = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/facade/config/qrcode/delete`, data);

// 二维码
export const pageArticle = (data: DataItem) => get(`${BASE_URL}/ymd/backend/facade/config/article/page`, data);
export const createArticle = (data: DataItem) => postFormData(`${BASE_URL}/ymd/backend/facade/config/article/create`, data);
export const updateArticle = (data: DataItem) => postFormData(`${BASE_URL}/ymd/backend/facade/config/article/update`, data);
export const deleteArticle = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/facade/config/article/delete`, data);

// router相关
export const listArea = (data: DataItem) => get(`${BASE_URL}/ymd/backend/facade/router/area/list`, data);
