import { get, post, postForm } from '@/https/req';
import { DataItem } from '@/types/app.global';

const BASE_URL = process.env.VUE_APP_BASE_URL;

export const pageDevice = (data: DataItem) => get(`${BASE_URL}/ymd/backend/device/device/page`, data);
export const listModelType = (data: DataItem) => get(`${BASE_URL}/ymd/backend/device/model/list`, data);

export const pageStockIn = (data: DataItem) => get(`${BASE_URL}/ymd/backend/device/stockIn/page`, data);
export const listStockInDetail = (data: DataItem) => get(`${BASE_URL}/ymd/backend/device/stockInDetail/list`, data);
export const stockIn = (data: DataItem) => postForm(`${BASE_URL}/ymd/backend/device/stockIn`, data);

export const pageAllocDoc = (data: DataItem) => get(`${BASE_URL}/ymd/backend/device/allocDoc/page`, data);
export const pageAllocFlow = (data: DataItem) => get(`${BASE_URL}/ymd/backend/device/allocFlow/page`, data);
